import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ConfirmLogoutDialogComponent } from 'src/app/common/components/confirm-logout-dialog/confirm-logout-dialog.component';
import { GlobalObjectService } from 'src/app/common/services/global-object/global-object.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import {
  ApplicationTypes,
  IFrameActions,
  IFrameMessageProps,
  MentorshipService,
} from 'src/app/common/services/mentorships/mentorship.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { PLAY_STORE_URL } from 'src/app/constants/app.constant';
import { IFRAME_LINK } from 'src/app/enum/iframe-url.enum';
import { BOY_IMG, GIRL_IMG, PLAYSTORE_IMG } from 'src/app/core/assets.location';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pw-centres-mentorship',
  templateUrl: './pw-centres-mentorship.component.html',
  styleUrls: ['./pw-centres-mentorship.component.scss'],
})
export class PwCentresMentorshipComponent implements OnInit {
  isSideNavOpen: boolean;
  urlArray: IFrameMessageProps[] = [];
  url = environment.VP_IFRAME_BASE_URL as string;
  isLoaded: boolean = true;
  iframe: any;
  mentorShipPageSub: Subscription;
  playstoreImg = PLAYSTORE_IMG;
  boyImg = BOY_IMG;
  girlImg = GIRL_IMG;
  userInfo: any;
  shouldShowProfile: boolean = true;
  cameFromStudy: boolean;

  constructor(
    private globalService: GlobalService,
    private globalObject: GlobalObjectService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private mentorShipServices: MentorshipService,
    private storageService: StorageService
  ) {
    this.getUserInfo();
    this.mentorShipPageSub =
      this.mentorShipServices.getMentorShipPageArray.subscribe((res) => {
        this.urlArray = [...res];
        this.urlArray = [...new Set(this.urlArray)];
        if (this.urlArray.length === 0) {
          const data: IFrameMessageProps = {
            action: IFrameActions.CHILD_NAVIGATION,
            value: '/mentorship',
            applicationType: ApplicationTypes.PW_MENTORSHIP,
          };
          this.urlArray.push(data);
          this.mentorShipServices.setMentorShipPageArray(this.urlArray);
        }
      });
    this.globalService.showSideNav$.next(true);
  }

  ngOnInit(): void {
    this.cameFromStudy =
      this.activatedRoute.snapshot.queryParamMap.get('came_from') === 'study' ||
      false;

    setTimeout(() => {
      this.globalService.showSideNav$.next(true);
    }, 850);
    this.globalObject.windowObj.addEventListener('message', this.eventHandler);
  }

  /* Toggling Side Navigation */
  toggleSideNav() {
    if (this.isSideNavOpen) {
      this.globalService.openSideNav(this.isSideNavOpen);
    } else {
      this.globalService.openSideNav(!this.isSideNavOpen);
    }
  }

  goBack() {
    const storageData = this.storageService?.getMentorShipPageArray() || [];
    if (storageData?.length > 1) {
      this.urlArray = [...storageData];
    }
    if (this.urlArray.length > 1) {
      let childData = this.urlArray[this.urlArray.length - 2];
      if (childData.value.includes('/vp')) {
        childData.value = childData.value.replace('/vp', '');
      }
      this.iframe.contentWindow.postMessage(childData, '*');
      this.urlArray.pop();
      this.mentorShipServices.setMentorShipPageArray(this.urlArray);
    } else {
      this.storageService.removeMentorShipPageArray();
      this.router.navigate(['batches/study']);
    }
  }

  showLottie() {
    return (
      this.router.url.includes('/batches') &&
      this.userInfo['profileId']['wallet'] > 0
    );
  }

  checkLoaded(event: any, iframe: HTMLIFrameElement) {
    this.iframe = iframe;
    this.isLoaded = false;
  }

  goToProfile() {
    this.router.navigate(['profile']);
  }

  handleLogout() {
    const dialogRef = this.dialog.open(ConfirmLogoutDialogComponent, {
      panelClass: 'test-submit-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        this.logoutUser();
      }
    });
  }

  logoutUser() {
    this.globalService.logoutUserGlobally('absolute', true);
  }

  openPlaystore() {
    this.globalObject.window!.open(PLAY_STORE_URL, '_system');
  }

  eventHandler = (event: MessageEvent<IFrameMessageProps>) => {
    if (
      event.data.action === IFrameActions.PARENT_NAVIAGTION &&
      event.data.applicationType === ApplicationTypes.PW_MENTORSHIP
    ) {
      if (event?.data?.value && event?.data?.value?.length > 0) {
        const valueObj = JSON.parse(event?.data?.value);
        this.router.navigateByUrl(valueObj?.path);
      }
    }
    if (
      event.data.action === IFrameActions.CHILD_NAVIGATION &&
      event.data.applicationType === ApplicationTypes.PW_MENTORSHIP
    ) {
      const pathArray: string[] = [];
      this.urlArray.forEach((item: IFrameMessageProps) => {
        pathArray.push(item.value);
      });
      if (!pathArray.includes(event.data.value)) {
        this.urlArray.push(event.data);
        this.mentorShipServices.setMentorShipPageArray(this.urlArray);
      }
    }
  };

  goToPurchase() {
    this.router.navigateByUrl('/my-purchase?came_from=my_profile_click');
  }

  getUserInfo() {
    this.userInfo = this.globalService.getUser().getValue() || {};
    const storageData = this.storageService.getMentorShipPageArray() || [];
    if (storageData?.length > 1) {
      this.url += `${storageData[storageData?.length - 1].value}`;
    } else {
      this.url = this.url + '/vp/mentorship';
    }
  }
}
