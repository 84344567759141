import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../storage/storage.service';
import { BehaviorSubject, catchError, lastValueFrom, map } from 'rxjs';
import { PPApiOptions } from '../../api/api.type';
import { ShowErrorService } from '../showError/show-error.service';
import { PPApiService } from '../../api/api.service';
import { AppUrlService } from '../../app-url/app-url.service';
import { handleError } from '../error-handler/error-handler.service';
import { GlobalService } from '../global/global.service';
import { CohortService } from '../cohort/cohort.service';
import { EntryComponentName } from '../../config/entry-point.config';
import { ViewAllResults } from 'src/app/pages/library/library-subject/library-subject.model';
import { StudentTestMapping } from 'src/app/pages/tests/tests.model';
import { LoaderService } from '../loader/loader.service';
import { TestSeriesService } from '../tests/test-series.service';
import { LibraryService } from '../library/library.service';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';
import { BatchService } from '../batch/batch.service';
import { BatchDetailModel } from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { KHAZANA_UNLOCK_BUTTON } from 'src/app/core/analytics-events';
import { differenceInSeconds } from 'date-fns';
import { ConversationService } from '../conversation/conversation.service';
import { ChapterContentService } from '../chapter-content/chapter-content.service';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { TestSidebarComponent } from 'src/app/pages/tests/test-sidebar/test-sidebar.component';
import { MAIN_PROGRAM_SLUG } from 'src/app/constants/global-constant/global-constant.service';
import { BATCH_CLUBBING_ENUM } from '../batch/batch.utils';
import { AFTER_PAYMENT_COMING_SOURCE } from '../payment/payment.type';
import { SNACKBAR_MESSAGE } from 'src/app/constants/message.constant';
import { Clipboard } from '@angular/cdk/clipboard';
import { GlobalObjectService } from '../global-object/global-object.service';
import { MatDialog } from '@angular/material/dialog';
import { VideoModalComponent } from 'src/app/pages/saarthi/components/video-modal/video-modal.component';
import { BatchDppQuiz } from '../batch/batch.modal';
import { PracticeService } from '../practice/practice.service';
import { PaymentService } from '../payment/payment.service';
import { environment } from 'src/environments/environment';
import { PartPaymentService } from '../part-payment/part-payment.service';

@Injectable({
  providedIn: 'root',
})
export class BatchRevampService {
  private cohortId: string;
  unleashKhazana: UnleashDataType;
  unleashAllClasses: UnleashDataType;
  activeBatch: BatchDetailModel;
  userInfo: any;
  unleashBatchDesc: UnleashDataType;
  unleashOrder: UnleashDataType;
  renderNewPowerBatch$ = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private storageService: StorageService,
    private cohortService: CohortService,
    private globalService: GlobalService,
    private showErrorService: ShowErrorService,
    private loaderService: LoaderService,
    private testService: TestSeriesService,
    private libraryService: LibraryService,
    private paymentService: PaymentService,
    private firebaseAnalytics: FirebaseAnalyticsService,
    private batchService: BatchService,
    private conversationService: ConversationService,
    private chapterContentService: ChapterContentService,
    private testInstructionDialog: NgDialogAnimationService,
    private ngZone: NgZone,
    private clipboard: Clipboard,
    private globalObjectService: GlobalObjectService,
    public dialog: MatDialog,
    private practiceService: PracticeService,
    private apiService: PPApiService,
    private appUrlService: AppUrlService,
    private activatedRoute: ActivatedRoute,
    private _partPaymentService: PartPaymentService
  ) {
    this.cohortId = this.cohortService.getCohortIdFromLocal();
    this.userInfo = this.globalService.getUser().getValue();
  }

  encodeConfig(config: any): string {
    try {
      const jsonString = JSON.stringify(config);
      return btoa(encodeURIComponent(jsonString));
    } catch (error) {
      console.error('Failed to encode config', error);
      return '';
    }
  }

  getLocalStorageItem(key: string): string | null {
    return typeof window !== 'undefined' && 'localStorage' in window
      ? localStorage.getItem(key)
      : null;
  }

  refirectToFBT(_d: any) {
    const utmParamsString = this.storageService.getCampaignData() || '';
    const data = {
      batchSlug: _d?.batchSlug as string,
      utmParamsString,
      cameFrom: _d?.cameFrom,
      queryParams: this.activatedRoute.snapshot.queryParams,
    };
    this.batchService.onBuyNowClick(_d?.batchSlug as string);
    this.paymentService.redirectToNewFBT(data);
    return;
  }

  khazanaPlayVideo(lecture: ViewAllResults) {
    const _entryPoint = `${EntryComponentName.KHAZANA_LECTURE_VIDEOS}_${this.cohortId}`;

    this.router.navigate(
      [`course-video-player/${lecture?.slug || lecture?._id}`],
      {
        queryParams: {
          programId: lecture?.programId?.slug || lecture?.programId?._id || '',
          videoFrom: 'course',
          fromLibrary: true,
          entryPoint: _entryPoint,
        },
      }
    );
  }

  KhazanaOpenNotes(note: { slug: string }) {
    const slug = note?.slug || '';
    this.router.navigate([`library/${slug}/pdf-viewer`]);
  }

  async khazanaOpenExercise(value: ExerciseDatails) {
    this.globalService.setTestBackUrl(this.router.url);
    const exerciseId = value?.exerciseId || '';
    const data = {
      batchId: value?.batchId,
      batchScheduleId: '',
      cameFrom: 'khazana',
    };
    this.storageService.setBatchIdSUbjectIdTest(data);

    let mappingObject = new StudentTestMapping({});
    const message = 'Please Wait...';
    await this.loaderService.loadData(message);
    try {
      const res: any = await lastValueFrom(
        this.testService.getTestStatus({ testListId: exerciseId })
      );
      if (res) {
        this.globalService.setQBankReturnURL(this.router.url);
        mappingObject = new StudentTestMapping(res);
        if (
          mappingObject.testActivityStatus === 'Finished' ||
          mappingObject.testActivityStatus === 'Submitted'
        ) {
          await this.router.navigate(
            [`q-bank-result/${mappingObject._id}/${exerciseId}`],
            {
              queryParams: {
                contentSlug: value?.contentSlug,
              },
            }
          );
        } else {
          await this.router.navigate([`q-bank-exercise/${exerciseId}`], {
            queryParams: {
              contentSlug: value?.contentSlug,
            },
          });
        }
      }
    } catch (e) {
      await this.showErrorService.showError(e);
      await this.loaderService.unloadData(message);
    } finally {
      const query = {
        programId: value?.programId ?? '',
        subjectId: value?.subjectId ?? '',
        chapterId: value?.chapterId ?? '',
        topicId: value?.topicId ?? '',
        contentId: value?.contentId ?? '',
      };
      this.markAsComplete(query);
      await this.loaderService.unloadData(message);
    }
  }
  async getBatchDetails(id: string) {
    try {
      const res = await lastValueFrom(this.batchService.getBatchDetails(id));
      console.log(res);
      this.activeBatch = new BatchDetailModel(res);
      this.batchService.setBatchData(this.activeBatch);
    } catch (e) {
      console.error(e);
    } finally {
      console.log(this.activeBatch, 'activebatch');
    }
  }

  async markAsComplete(params: any) {
    if (params) {
      try {
        const res = await lastValueFrom(
          this.libraryService.markContentComplete(
            params.programId,
            params.subjectId,
            params.chapterId,
            params.topicId,
            params.contentId
          )
        );
      } catch (e) {
        this.showErrorService.showError(e);
      }
    }
  }

  async handleEMIPayNowClick(data: any) {
    const { batchDetails, shouldDisable, emiDetail, page } = JSON.parse(
      data?.value
    );

    if (shouldDisable) {
      return;
    }
    if (emiDetail?.status !== 'PAID') {
      await this._partPaymentService.handleEMIPayOnClick({
        batchId: batchDetails?._id,
        surl: window.location.href,
        furl: window.location.href,
        installment_number: emiDetail?.installmentNumber,
        due_date: emiDetail?.dueDate,
        time_window: emiDetail?.daysLeft,
        page: page,
      });
    }
  }

  unlockKhazana(batchDetails: BatchDetailModel) {
    const routeToNewFbt = this.paymentService._shouldRunNewFBT$.getValue();
    this.storageService.setLastVisitedUrl(this.router.url);
    if (batchDetails.isBatchPlusEnabled) {
      this.batchService.openBatchPlanComparisonTable(
        batchDetails,
        'khazana_page',
        'khazana_unlock',
        '',
        () => {},
        true
      );
      return;
    }

    if (
      batchDetails &&
      batchDetails.isPurchased &&
      ((batchDetails?.config.isPremium &&
        batchDetails?.plan.toLowerCase() === 'premium') ||
        !batchDetails?.config.isPremium)
    ) {
      this.storageService.setAfterPaymentComingSource('khazana');
      if (routeToNewFbt) {
        const data = {
          khazanaProgramId: batchDetails?.khazanaProgramId || '',
          cameFrom: 'khazana_page',
        };
        this.paymentService.redirectToNewFBT(data, '/khazana');
        return;
      }

      this.router.navigate([
        `batches/${batchDetails.slug.toLowerCase()}/batch-overview/${
          //TODO add khazana slug
          batchDetails?.khazanaProgramId || ''
        }/khazana-payment`,
      ]);
    } else {
      this.batchService.onBuyNowClick(batchDetails?.slug || batchDetails?._id);

      if (routeToNewFbt) {
        const data = {
          batchSlug: batchDetails?._id,
          cameFrom: 'khazana_page',
        };
        this.paymentService.redirectToNewFBT(data);
        return;
      }

      this.router.navigate([
        `batches/${batchDetails.slug.toLowerCase()}/batch-overview/order-summary`,
      ]);
    }

    const dataForEvents = {
      batch_name: batchDetails?.name,
      page_title: batchDetails?.name,
      page_name: 'Khazana Landing Page',
    };
    this.firebaseAnalytics.logEvents(KHAZANA_UNLOCK_BUTTON, dataForEvents);
  }

  async getExpiryUnleashFeatureTag(): Promise<UnleashDataType> {
    const unleashQuery: UnleashApiPayloadType = {
      userId: this.userInfo.id,
      featureName: 'expire-prd',
      experimentName: 'expire-prd',
      customData: {
        id: '',
      },
      sendAnalytics: true,
    };
    try {
      const res: UnleashDataType = await this.unleashFeatureFlag(unleashQuery);
      return res;
    } catch (err) {
      console.error(err);
      return {} as UnleashDataType;
    }
  }

  updateUrl(url: string) {
    this.router.navigate([], {
      queryParams: {
        batchChildUrl: url,
      },
      queryParamsHandling: 'merge',
    });
  }

  async getPremiumUnleashFeatureTag(): Promise<UnleashDataType> {
    const unleashQuery: UnleashApiPayloadType = {
      userId: this.userInfo.id,
      featureName: 'vp-live-rollout',
      experimentName: 'vp-live-rollout',
      customData: {
        id: '',
      },
      sendAnalytics: true,
    };
    try {
      const res: UnleashDataType = await this.unleashFeatureFlag(unleashQuery);
      return res;
    } catch (err) {
      console.error(err);
      return {} as UnleashDataType;
    }
  }

  async newOpenContent(
    url: string,
    attachmentId?: string,
    activeSection?: string
  ) {
    const activePdfIdQuery = {
      activeSection: activeSection,
      pdfId: attachmentId,
    };
    if (activeSection?.length) {
      this.chapterContentService.setActiveSection(activePdfIdQuery);
    }

    this.globalService.setPdfUrl(
      JSON.stringify({
        src: url,
      })
    );
    this.globalService.setPdfBackUrl(this.router.url);
    await this.router.navigate(['batches/open-pdf']);
  }

  async startClass(videoData: any, batchSubjectId: string) {
    const entryPoint = videoData?.entryComponentName;
    let _isLive = videoData?.isLive;

    const loaderMessage = 'Please wait...';
    const timeRemainingStart = differenceInSeconds(
      new Date(videoData?.startTime),
      new Date()
    );
    if (timeRemainingStart / 86400 > 2) {
      this.loaderService.unloadData(loaderMessage);
      const message =
        'Class Will be live after ' +
        Math.round(timeRemainingStart / 86400) +
        'days';
      await this.globalService.showSnackBar(message);
      return;
    } else if (timeRemainingStart / 86400 > 1) {
      this.loaderService.unloadData(loaderMessage);
      const message = 'Class Will be live tomorrow';
      await this.globalService.showSnackBar(message);
      return;
    } else if (timeRemainingStart / 86400 > 0) {
      this.loaderService.unloadData(loaderMessage);
      const message =
        'Class Will be live after ' + this.formatTime(timeRemainingStart);
      await this.globalService.showSnackBar(message);
      return;
    }
    let params: any;
    const timeRemainingEnd = differenceInSeconds(
      new Date(videoData?.endTime),
      new Date()
    );
    if (timeRemainingEnd > 0) {
      _isLive = true;
      // if (event.isChatEnabled && event.conversationId) {
      //   params['conversationId'] = event.conversationId;
      // }
      const loaderMessage = 'Please wait...';
      try {
        this.loaderService.unloadData(loaderMessage);
        const response = await this.conversationService.joinClass(
          videoData?.batchId,
          videoData?.batchSubjectId,
          videoData?._id,
          { conversationId: videoData?.conversationId }
        );

        // @ts-ignore
        if (response && response['data']) {
          // @ts-ignore
          params['joiningId'] = response['data']._id;
          // @ts-ignore
          this.globalService.setJoiningId(response['data']._id);
        }
        if (videoData?.conversationId) {
          this.globalService.showSnackBar('Joined Chat');
        }
      } catch (e) {
        console.log(e);
        this.loaderService.unloadData(loaderMessage);
      }
    } else if (videoData?.restrictedSchedule && videoData?.restrictedTime > 0) {
      try {
        const response = await this.batchService.checkWatchLimit(
          videoData?.batchId,
          videoData?.subjectId,
          videoData?._id,
          {}
        );
        // @ts-ignore
        const userWatchTime = response['data'];
        this.loaderService.unloadData(loaderMessage);
        if (userWatchTime.watchTime > videoData?.restrictedTime) {
          return;
        } else {
          this.globalService.showSnackBar(
            `You have consumed ${new Date(userWatchTime.watchTime * 1000)
              .toISOString()
              .substr(11, 8)} from ${Math.round(
              videoData?.restrictedTime / 60
            )}min.`
          );
        }
      } catch (e) {
        this.loaderService.unloadData(loaderMessage);
        await this.showErrorService.showError(e);
      }
    }
    this.globalService.setVideoDetails(event);
    this.batchService.setVideoBackUrl(this.router.url);
    const _entryPoint = `${entryPoint}_${this.cohortId}`;
    if (
      videoData?.urlType === 'premiumWebrtc' &&
      typeof window !== 'undefined'
    ) {
      const _finalURL = `/classroom/pw-live-class/${videoData?.batchId}`;
      window.location.replace(_finalURL);
    } else {
      this.router.navigateByUrl(
        `batches/${videoData?.batchId}/subjects/${
          videoData?.subjectId
        }/subject-details/all/batch-video-player?scheduleId=${
          videoData?._id
        }&dRoom=${videoData?.dRoomId}&batchSubjectId=${batchSubjectId}&type=${
          videoData?.urlType
        }&cameFrom=${
          videoData?.activeSection === 'Lectures' ? 'lecture' : 'dpp-video'
        }&entryPoint=${_entryPoint}`
      );
    }

    this.loaderService.unloadData('Loading...');
  }

  formatTime(timeInSeconds: any) {
    const hours: number = Math.floor(timeInSeconds / 3600);
    const minutes: number = Math.floor((timeInSeconds % 3600) / 60);

    return (
      ('00' + hours).slice(-2) +
      ' hours ' +
      ('00' + minutes).slice(-2) +
      ' minutes ' +
      ('00' + Math.floor(timeInSeconds - minutes * 60)).slice(-2) +
      ' seconds'
    );
  }
  redirectFn(url: string) {
    this.router.navigateByUrl(url);
  }

  testRedirection(value: string, type: 'objective' | 'subjective') {
    const valueFromChild = JSON.parse(value);

    if (valueFromChild?.isFromBatch) {
      localStorage.setItem('BATCH_TEST_BACK_URL', this.router.url);
    }
    this.router.navigate(
      [`batches/${valueFromChild.batchId}/batch-overview/tests/newbatch`],
      {
        queryParams: {
          type: 'test-series',
          selected: type,
          batchSlug: valueFromChild.batchId,
          fromBatch: true,
          childUrl: valueFromChild.url,
        },
      }
    );
  }

  joinBattle(
    _id: string,
    studentSideProgressStatus: string,
    studentBattleStatus: string
  ) {
    if (studentSideProgressStatus === 'ENDED') {
      this.router.navigate(
        [`tests/battle-ground-result-analyzing-screen/${_id}`],
        {
          queryParams: {
            cameFrom: window.location.href.replace(/&?testId=[^&]*/g, ''),
            cameFromScreen: 'documents',
          },
        }
      );
      return;
    }
    if (studentBattleStatus === 'COMPLETED') {
      this.router.navigate(
        [`tests/battle-ground-result-analyzing-screen/${_id}`],
        {
          queryParams: {
            cameFrom: window.location.href.replace(/&?testId=[^&]*/g, ''),
            cameFromScreen: 'documents',
          },
        }
      );
      return;
    }
    if (studentSideProgressStatus === 'UPCOMING') {
      this.router.navigate([`/tests/battle-ground-test-engine/${_id}`], {
        queryParams: {
          isUpcoming: true,
          cameFrom: window.location.href.replace(/&?testId=[^&]*/g, ''),
          cameFromScreen: 'documents',
        },
      });
      return;
    }
    if (studentSideProgressStatus === 'LIVE') {
      this.router.navigate([`/tests/battle-ground-test-engine/${_id}`], {
        queryParams: {
          type: 'dppBattle',
          isResume: studentBattleStatus === 'PAUSED' ? 'true' : '',
          cameFrom: window.location.href.replace(/&?testId=[^&]*/g, ''),
          cameFromScreen: 'documents',
        },
      });
      return;
    }
  }
  premiumBuyNow(batchDetails: BatchDetailModel) {
    const routeToNewFbt = this.paymentService._shouldRunNewFBT$.getValue();
    this.storageService.setLastVisitedUrl(this.router.url);

    if (batchDetails) {
      this.batchService.onBuyNowClick(batchDetails?.slug || batchDetails?._id);

      if (routeToNewFbt) {
        const data = {
          batchSlug: batchDetails?._id,
          cameFrom: 'premium_page',
        };
        this.paymentService.redirectToNewFBT(data);
        return;
      }
      this.router.navigate([
        `batches/${batchDetails.slug}/batch-overview/order-summary`,
      ]);
    }
  }

  testOpenSideModal(event: any) {
    const { testId, showTestInstructions, enableStudySource } = JSON.parse(
      event.data?.value
    );
    this.testInstructionDialog.open(TestSidebarComponent, {
      panelClass: 'test-sidebar',
      position: { right: '0px' },
      disableClose: true,
      data: { testId, showTestInstructions, enableStudySource },
      animation: {
        to: 'left',
        incomingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
        outgoingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
      },
    });
  }

  testPaymentRedirection(batchData: BatchDetailModel, routeToNewFbt: boolean) {
    if (routeToNewFbt) {
      const config = {
        batchSlug: batchData?._id,
        cameFrom: 'test-series',
      };

      this.paymentService.redirectToNewFBT(config);
      return;
    }

    this.router.navigate(
      [`batches/${batchData?.slug}/batch-overview/order-summary`],
      {
        queryParams: {
          cameFrom: 'batch-details',
        },
      }
    );
  }

  startTest(event: any, batchDetail: BatchDetailModel) {
    if (
      event.data.action === IFrameActions.START_TEST &&
      event.data.applicationType === ApplicationTypes.PW_TEST_SERIES
    ) {
      const valueFromChild = JSON.parse(event.data.value);

      let practiceData = valueFromChild?.test;

      if (!!valueFromChild.tag2) {
        practiceData = { ...practiceData, tag2: valueFromChild?.tag2 };
      }

      this.globalService.setTestBackUrl(this.router.url);
      const _isNewTestSeriesEnabled =
        environment.newTestSeries.enableNewTestSeriesFromBatches;

      this.globalService.setNewTestBackUrl(this.router.url);
      this.practiceService.startPractice({
        practiceData: practiceData,
        practiceType: 'test',
        source: 'BATCH_TEST_SERIES',
        batchId: batchDetail?._id as string,
        fetchInstructions: false,
        localstorageKeysToClear: ['REDUX_STATE', 'ORG_DATA'],
        testMapId: valueFromChild?.test?.testStudentMappingId as string,
      });
    }
  }

  testAfterSubmit(event: any, batchDetail: BatchDetailModel) {
    const valueFromChild = JSON.parse(event.data.value);
    let practiceData = valueFromChild?.test;

    if (!!valueFromChild.tag2) {
      practiceData = { ...practiceData, tag2: valueFromChild?.tag2 };
    }
    this.globalService.setTestBackUrl(this.router.url);
    const _isNewTestSeriesEnabled =
      environment.newTestSeries.enableNewTestSeriesFromBatches;

    this.globalService.setNewTestBackUrl(this.router.url);
    this.practiceService.startPractice({
      practiceData: practiceData,
      practiceType: 'test',
      source: 'BATCH_TEST_SERIES',
      batchId: batchDetail?._id as string,
      fetchInstructions: false,
      localstorageKeysToClear: ['REDUX_STATE', 'ORG_DATA'],
      testMapId: valueFromChild?.test?.testStudentMappingId as string,
    });
  }

  testVideoPlay(event: any) {
    const videoDetails = JSON.parse(event.data.value);
    if (videoDetails.videoUrl.length > 0 || videoDetails.embedCode.length > 0) {
      this.globalService.setVideoDetails(videoDetails);
      this.globalService.setIframeRedirection({
        parent: '/test-series',
        child: this.router.url,
      });
      this.ngZone.run(() => {
        this.router.navigate(['video-player-public'], {
          queryParams: {
            type: videoDetails.videoType,
            mode: 'public-feeds',
          },
        });
      });
    }
  }

  saarthiTeacherConnect(event: any) {
    const subjectDetails = JSON.parse(event.data.value);
    this.globalService.setChatSaarthiBackUrl(this.router.url);
    localStorage.setItem('SAARTHI_ID', subjectDetails?.saarthiId);
    localStorage.setItem('selectChatSubject', subjectDetails?.subject?.name);
    this.storageService.setValue(
      'ACTIVE_SAARTHI_PLAN',
      subjectDetails.activePlan,
      'json'
    );

    if (subjectDetails.subject?._id) {
      this.router.navigate([`/saarthi/${subjectDetails.subject?._id}/chat`], {
        queryParams: {
          cameFrom: 'batches',
          source: BATCH_CLUBBING_ENUM.BATCH_CLUBBING,
        },
      });
    }
  }

  saarthiPlanRedirect(
    event: any,
    batchData: BatchDetailModel,
    routeToNewFbt: boolean
  ) {
    let saarthiId = '';
    this.storageService.setLastVisitedUrl(this.router.url);
    const data = JSON.parse(event?.data?.value);
    if (data && data?.saarthiId) {
      saarthiId = data?.saarthiId;
    }
    if (data && data?.redirectTo?.toLowerCase() === 'saarthi') {
      this.redirectToNewSaarthiFBT(saarthiId);
    } else {
      if (batchData?.isPurchased) {
        if (
          batchData?.config?.isPremium &&
          batchData?.plan?.toLowerCase() !== 'premium'
        ) {
          if (routeToNewFbt) {
            const config = {
              batchSlug: batchData?._id,
              cameFrom: 'saarthi',
            };

            this.paymentService.redirectToNewFBT(config);
            return;
          }

          this.router.navigate(
            [`batches/${batchData?.slug}/batch-overview/order-summary`],
            {
              queryParams: {
                cameFrom: 'batch-details',
              },
            }
          );
        } else {
          this.redirectToNewSaarthiFBT(saarthiId);
          this.storageService.setAfterPaymentComingSource(
            AFTER_PAYMENT_COMING_SOURCE.BATCH_SAARTHI
          );
        }
      } else {
        if (routeToNewFbt) {
          const config = {
            batchSlug: batchData?._id,
            cameFrom: 'saarthi',
          };

          this.paymentService.redirectToNewFBT(config);
          return;
        }

        this.router.navigate(
          [`batches/${batchData?.slug}/batch-overview/order-summary`],
          {
            queryParams: {
              cameFrom: 'batch-details',
            },
          }
        );
      }
    }
  }

  redirectToNewSaarthiFBT(saarthiId: string) {
    this.storageService.setLastVisitedUrl(this.router.url);
    const cohortConfig =
      this.storageService.getValue('UserCohortConfig', 'json') || {};
    const saarthiDetails = {
      saarthiId: saarthiId,
      cohort_name: cohortConfig?.name ?? '',
      cameFrom: 'batch-details',
    };
    this.paymentService.redirectToNewFBT(saarthiDetails, 'saarthi');
  }

  copyClipBoard(item: any) {
    if (!item) {
      this.globalService.showSnackBar(SNACKBAR_MESSAGE.NO_CODE_PRESENT);
    } else {
      const isCopied: boolean = this.clipboard.copy(item);
      if (isCopied) {
        this.globalService.showSnackBar(SNACKBAR_MESSAGE.COPIED);
      }
    }
  }

  referWhatsapp(referralCode: string, amount: string) {
    let url = 'https://links.physicswallah.live/ref';
    const inviteText = `“I recommend you to try Saarthi by PW  - India's Most Loved EdTech live doubt solving Platform! One-Time Only: Get FLAT Rs. ${amount} OFF on your first batch purchase!. Use my referral code ${referralCode}`;
    const urlEncodedText = encodeURI(inviteText);
    const social = [
      {
        icon: 'logo-facebook',
        title: 'Facebook',
        href: `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${urlEncodedText}`,
        class: 'fb',
      },
      {
        icon: 'logo-whatsapp',
        title: 'WhatsApp',
        href: `https://web.whatsapp.com/send?text=${urlEncodedText}. ${url}`,
        class: 'wp',
      },
    ];
    this.globalObjectService.window!.open(social[1].href, '_blank');
  }

  referEmail(referralCode: string, amount: string) {
    let url = 'https://links.physicswallah.live/ref';
    const inviteText = `“I recommend you to try Saarthi by PW  - India's Most Loved EdTech live doubt solving Platform! One-Time Only: Get FLAT Rs. ${amount} OFF on your first batch purchase!. Use my referral code ${referralCode}`;
    const urlEncodedText = encodeURI(inviteText);
    this.globalObjectService.window!.open(
      `mailto:abc@xyz.com?subject=Refer-Earn&body=${inviteText} ${url}`,
      '_system'
    );
  }

  saarthiOPenVideModal(event: any) {
    const videoDetails = JSON.parse(event.data.value);
    if (
      videoDetails?.videoData?.videoUrl?.length > 0 ||
      videoDetails?.videoData?.embedCode?.length > 0
    ) {
      if (videoDetails?.videoType?.length > 0) {
        this.openVideoModal(videoDetails.videoType, videoDetails.videoData);
      } else {
        this.globalService.showSnackBar('Video Not Available');
      }
    } else {
      this.globalService.showSnackBar('Video Not Available');
    }
  }

  openVideoModal(videoType: string, videoDetails: any) {
    const dialogRef = this.dialog.open(VideoModalComponent, {
      panelClass: 'saarthi-video-modal',
      width: '1036px',
      data: {
        videoType: videoType,
        videoDetails: videoDetails,
      },
    });
  }

  async subjectiveDpp(
    dpp: BatchDppQuiz,
    isSubjective: boolean,
    batchId: string,
    batchName: string,
    subjectName: string,
    isMasterBatch: boolean
  ) {
    this.globalService.setDppBackUrl(this.router.url);
    this.globalService.setQBankReturnURL(this.router.url);

    this.setBatchIdSubjectId(batchId, dpp?.scheduleId);
    this.globalService.setTestBackUrl(encodeURI(this.router.url));

    if (isSubjective) {
      let childUrl = this.getQueryParams(
        batchId,
        batchName,
        dpp?.scheduleId,
        `/test/${dpp.test._id}/subjective-details`
      );

      this.router.navigate([`tests/${dpp.test._id}/new-test-result`], {
        queryParams: {
          childUrl,
        },
      });
    } else {
      // REDIRECTING TO NEW PRACTICE ENGINE
      this.practiceService.startPractice({
        practiceData: dpp,
        fetchInstructions: false,
        practiceType: 'dpp',
        source: 'BATCH_QUIZ',
        scheduleId: dpp?.scheduleId || '',
        subjectName: subjectName,
        localstorageKeysToClear: [
          'testDetails',
          'testType',
          'questionTimer',
          'preDraft-meta',
        ],
        saarthi: {
          enabled: true,
          batchId,
          isPremium: isMasterBatch,
        },
        eventsData: {
          source: 'BATCH_SECTION',
        },
        batchId: batchId,
      });
    }
  }

  setBatchIdSubjectId(batchId: string, batchScheduleId: string) {
    const data = {
      batchId: batchId,
      batchScheduleId: batchScheduleId,
      cameFrom: 'classroom',
    };
    this.storageService.setBatchIdSUbjectIdTest(data);
  }

  getQueryParams = (
    batchId: string,
    batchName: string,
    batchScheduleId: any,
    url: string
  ) => {
    let source = 'BATCH_TEST_SERIES';
    url += `?batchId=${batchId}&batchName=${batchName}&source=${source}&batchScheduleId=${batchScheduleId}&cameFrom=DPP`;
    return url;
  };

  getUnleashFeatureFlag(data: UnleashApiPayloadType) {
    const url = this.appUrlService.UNLEASH_FEATURE_FLAG();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.post(data, options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  async unleashFeatureFlag(
    data: UnleashApiPayloadType
  ): Promise<UnleashDataType> {
    try {
      const res = await lastValueFrom(this.getUnleashFeatureFlag(data));
      if (res) {
        return <UnleashDataType>res;
      }
      return <UnleashDataType>{};
    } catch (error) {
      this.showErrorService.showError(error);
      return <UnleashDataType>{};
    }
  }

  async getKhazanaUnleashFeatureFlag(): Promise<UnleashDataType> {
    const unleashQuery: UnleashApiPayloadType = {
      userId: this.userInfo.id,
      featureName: 'khazana-revamp-fe',
      experimentName: 'batch-revamp-unleash',
      customData: {
        id: '',
      },
      sendAnalytics: false,
    };
    if (this.unleashKhazana) {
      return this.unleashKhazana;
    } else {
      this.unleashKhazana = await this.unleashFeatureFlag(unleashQuery);
      return this.unleashKhazana;
    }
  }

  async getAllClassesUnleashFeatureFlag(): Promise<UnleashDataType> {
    const unleashQuery: UnleashApiPayloadType = {
      userId: this.userInfo.id,
      featureName: 'all-classes-fe',
      experimentName: '',
      customData: {},
      sendAnalytics: false,
    };
    if (this.unleashAllClasses) {
      return this.unleashAllClasses;
    } else {
      this.unleashAllClasses = await this.unleashFeatureFlag(unleashQuery);
      return this.unleashAllClasses;
    }
  }

  handleSaarthiRedirection(data: any, activatedRoute: ActivatedRoute) {
    const { batchDetails, shouldDisable, typeId } = JSON.parse(data?.value);

    const cameFrom =
      this.activatedRoute.snapshot.queryParamMap.get('came_from') || '';
    if (!batchDetails?.isPurchased) return;

    if (shouldDisable) {
      return;
    }

    this.globalService.setPremiumSaarthiData(typeId);

    this.globalService.removeChatToDppBackUrl();
    this.globalService.removeChatToTestSeriesBackUrl();
    if (batchDetails?.isPurchased && typeId) {
      this.router.navigate(['saarthi-premium'], {
        relativeTo: activatedRoute,
        queryParams: {
          ...(cameFrom === 'batchlist' && {
            came_from: cameFrom,
          }),
        },
      });
    } else {
      this.globalService.showSnackBar(SNACKBAR_MESSAGE.MENTOR_IS_NOT_AVAILABLE);
    }
  }

  setRenderNewPowerBatch(data: boolean) {
    this.storageService.setValue('RENDER_NEW_POWER_BATCH', data, 'string');
    this.renderNewPowerBatch$.next(data);
  }

  get _renderNewPowerBatch$() {
    return this.renderNewPowerBatch$;
  }
}

export enum IFrameActions {
  DEMO_VIDEOS = 'DEMO_VIDEOS',
  JOIN_BG_BATTLE = 'JOIN_BG_BATTLE',
  DPP = 'DPP',
  REDIRECT_TO_FBT = 'REDIRECT_TO_FBT',
  STUDY_REDIRECTION = 'STUDY_REDIRECTION',
  MY_BATCHES_REDIRECTION = 'MY_BATCHES_REDIRECTION',
  GO_TO_SAT = 'GO_TO_SAT',
  HAZANA_VIDEO_PLAY = 'KHAZANA_VIDEO_PLAY',
  KHAZANA_VIDEO_PLAY = 'KHAZANA_VIDEO_PLAY',
  KHAZANA_QUIZ = 'KHAZANA_QUIZ',
  KHAZANA_NOTES = 'KHAZANA_NOTES',
  KHAZANA_UNLOCK = 'KHAZANA_UNLOCK',
  CHILD_NAVIGATION = 'CHILD_NAVIGATION',
  POP_URL_ARRAY = 'POP_URL_ARRAY',
  ATTEND_WEBINAR = 'ATTEND_WEBINAR',
  PLAY_DEMO_VIDEOS = 'PLAY_DEMO_VIDEOS',
  SHOW_BATCH_PLUS_TABLE = 'SHOW_BATCH_PLUS_TABLE',
  START_CLASS = 'START_CLASS',
  OPEN_PDF = 'OPEN_PDF',
  CLUBBING_INFINITE_PRACTICE = 'CLUBBING_INFINITE_PRACTICE',
  CLUBBING_INFINITE_MENTORSHIP = 'INFINITE_MENTORSHIP',
  CLUBBING_TEST_AND_PASS = 'CLUBBING_TEST_AND_PASS',
  CLUBBING_UPSC_BANNER_MENTORSHIP = 'CLUBBING_UPSC_BANNER_MENTORSHIP',
  INFINITE_PRACTICE_REDIRECTION = 'INFINITE_PRACTICE_REDIRECTION',
  PREMIUM_BUYNOW = 'PREMIUM_BUYNOW',
  PREMIUM_TEST_SERIES_REDIRECTION = 'PREMIUM_TEST_SERIES_REDIRECTION',
  PLAN_BUY_NOW_CLICK = 'PLAN_BUY_NOW_CLICK',
  SAARTHI_IFRAME_LOAD = 'SAARTHI_IFRAME_LOAD',
  GO_TO_OBJECTIVE = 'GO_TO_OBJECTIVE',
  CLUBBING_SAHAYAK = 'CLUBBING_SAHAYAK',
  CLUBBING_DEFAULT = 'CLUBBING_DEFAULT',
  GO_TO_SUBJECTIVE = 'GO_TO_SUBJECTIVE',
  BATCH_PRICE_CARD_SUMMARY_REDIRECTION = 'BATCH_PRICE_CARD_SUMMARY_REDIRECTION',
  SAARTHI_REDIRECTION = 'SAARTHI_REDIRECTION',
  PART_PAYMENT_PAY_NOW = 'PART_PAYMENT_PAY_NOW',
  CONTACT_US = 'CONTACT_US',
  HANDLE_GA_EVENTS = 'HANDLE_GA_EVENTS',
  HANDLE_MOENGAGE_EVENTS = 'HANDLE_MOENGAGE_EVENTS',
  PARENT_NAVIAGTION = 'PARENT_NAVIAGTION',
  GO_TO_VIDEO_SOLUTION = 'GO_TO_VIDEO_SOLUTION',
  OPEN_SIDE_PANEL = 'OPEN_SIDE_PANEL',
  REDIRECT_ORDER_SUMMARY = 'REDIRECT_ORDER_SUMMARY',
  START_TEST = 'START_TEST',
  AFTER_SUBMIT = 'AFTER_SUBMIT',
  TAB_SWITCH = 'TAB_SWITCH',
  BUY_NOW = 'BUY_NOW',
  PLAY_VIDEO = 'PLAY_VIDEO',
  IFRAME_LOADED = 'IFRAME_LOADED',
  TEACHER_CONNECT = 'TEACHER_CONNECT',
  REFER_EARN_REDIRECT = 'REFER_EARN_REDIRECT',
  PLANNER_REDIRECT = 'PLANNER_REDIRECT',

  PLAN_REDIRECT = 'PLAN_REDIRECT',
  CLIPBOARD_COPY = 'CLIPBOARD_COPY',
  SHARE_WHATSAPP = 'SHARE_WHATSAPP',
  SHARE_EMAIL = 'SHARE_EMAIL',
  SET_SELECTED_SAARTHI = 'SET_SELECTED_SAARTHI',
  ACTIVE_SAARTHI_DATA = 'ACTIVE_SAARTHI_DATA',
  SET_BATCH_TO_LOCAL = 'SET_BATCH_TO_LOCAL',
  BATCH_DESCRIPTION_ON_CHANGE_SECTION = 'BATCH_DESCRIPTION_ON_CHANGE_SECTION',
  ALL_CLASSES_HANDLE_TEST_PRESS = 'ALL_CLASSES_HANDLE_TEST_PRESS',
  ALL_CLASSES_TOGGLE_UNLOCK_MODAL = 'ALL_CLASSES_TOGGLE_UNLOCK_MODAL',
  ALL_CLASSES_HANDLE_UPGRADE_CLICK = 'ALL_CLASSES_HANDLE_UPGRADE_CLICK',
  ALL_CLASSES_GET_BATCH_UPGRADE_BANNER = 'ALL_CLASSES_GET_BATCH_UPGRADE_BANNER',
  IFRAME_HEIGHT = 'IFRAME_HEIGHT',
  EXPLORE_BATCH = 'EXPLORE_BATCH',
  COMMUNITY_REDIRECTION = 'COMMUNITY_REDIRECTION',
  MAHAPACK_BATCH_DATA = 'MAHAPACK_BATCH_DATA',
  IS_MAHAPACK = 'IS_MAHAPACK',
  REDIRECT_FUNCTION = 'REDIRECT_FUNCTION',
  MENTORSHIP_REDIRECTION = 'MENTORSHIP_REDIRECTION',
  MENTORSHIP_ANNOUNCEMENT_REDIRECTION = 'MENTORSHIP_ANNOUNCEMENT_REDIRECTION',
  GO_TO_AI_EVALUATION = 'GO_TO_AI_EVALUATION',
  PREMIUM_BATCH_CHANGE = 'PREMIUM_BATCH_CHANGE',
  HELP_CENTRE_REDIRECTION = 'HELP_CENTRE_REDIRECTION',
}

export enum ApplicationTypes {
  NEW_BATCH_SERVICE = 'NEW_BATCH_SERVICE',
  PW_SAARTHI = 'PW_SAARTHI',
  PW_TEST_SERIES = 'PW_TEST_SERIES',
}

export interface IFrameMessageProps {
  action: IFrameActions;
  value: string;
  applicationType: ApplicationTypes;
  pathname?: string;
}

interface ExerciseDatails {
  batchId: string;
  exerciseId: string;
  programId: string;
  subjectId: string;
  chapterId: string;
  topicId: string;
  contentSlug: string;
  contentId: string;
}

export interface UnleashDataType {
  isEnabled: boolean;
  variantData: VariantData;
}

export interface VariantData {
  name: string;
  payload: Payload;
  enabled: boolean;
  feature_enabled: boolean;
  featureEnabled: boolean;
}

export interface Payload {
  type: string;
  value: string;
}

export interface UnleashApiPayloadType {
  userId: string;
  featureName: string;
  experimentName: string;
  customData: any;
  sendAnalytics: boolean;
}
