<div class="parent-container flex flex-col justify-between h-screen">
  <article class="flex flex-col gap-32">
    <div class="flex justify-end w-full p-3.5">
      <img
        [src]="closeImg"
        alt="close"
        (click)="close()"
        class="cursor-pointer"
      />
    </div>
    <div class="flex flex-col gap-7 lets-study-section mx-auto">
      <section class="w-full flex flex-col gap-3.5 justify-center items-center">
        <img [src]="checkBadge" alt="success" class="check-badge" />
        <span class="heading-4 bold text-center leading-6"
          >You have successfully enrolled.</span
        >
        <span class="body-2 semibold text-center"
          >You can find this batch in
          <span class="bold">My Batches</span> section</span
        >
      </section>
      <div
        class="bg-primary text-white semibold body-2 w-full py-4 flex items-center justify-center rounded-lg cursor-pointer"
        (click)="navigateToMyBatches()"
      >
        Let’s Study
      </div>
    </div>
  </article>

  <div class="w-full flex justify-end items-end">
    <img [src]="students" alt="students" />
  </div>
</div>
