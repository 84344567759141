<main class="modal_container bg-gray-900 h-fit relative">
  <!-- header -->
  <div class="header_bg pt-12"></div>
  <button
    class="w-8 h-8 flex z-10 justify-center items-center hover_black absolute cursor-pointer top-4 right-4"
    (click)="close('api')"
  >
    <mat-icon class="text-base bold">close</mat-icon>
  </button>
  <div
    class="flex justify-center items-center flex-col gap-4 p-4 absolute top-7"
  >
    <span class="text-white heading-4 semi-bold text-center"
      >Level Up To {{ upgradeData.title }}</span
    >
    <p class="textbf px-3 text-center">
      Unlock the full potential of your learning journey with the Infinity plan
      including offers like...
    </p>
  </div>
  <div
    class="w-full px-6 flex gap-4 flex-col justify-center items-center mt-24 sm:mt-20 md:mt-8"
  >
    <div
      class="flex flex-col gap-4 w-full px-4 py-2.5 rounded-lg list_bg"
      *ngIf="upgradeData.upgradeBanner.itemImages.length > 0"
      [ngClass]="{ 'gap-4': upgradeData.upgradeBanner.itemImages.length > 1 }"
    >
      <!-- first row -->
      <div class="flex gap-4 w-full">
        <div
          class="wrapper_div w-full h-[95px]"
          *ngFor="
            let banner of upgradeData.upgradeBanner.itemImages.slice(
              0,
              splitSection
            )
          "
        >
          <div class="w-full h-full inner_div rounded">
            <div class="img_div items-center flex justify-center">
              <img class="" [src]="banner" alt="banner" />
            </div>
          </div>
        </div>
      </div>
      <!-- second row -->
      <div class="flex gap-4 w-full">
        <div
          class="wrapper_div w-full h-[95px]"
          *ngFor="
            let banner of upgradeData.upgradeBanner.itemImages.slice(
              splitSection
            )
          "
        >
          <div class="w-full h-full inner_div rounded">
            <div class="img_div flex justify-center items-center">
              <img class="" [src]="banner" alt="banner" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="text-xs bold text-white">And Many More...</span>
    <div
      class="w-fit flex items-center gap-2 off_rocket p-2 rounded overflow-hidden list_bg"
    >
      <img [src]="rocketImg" alt="Rocket" />
      <span class="text-xs textbf"
        >Infinity offerings availability depends upon selected infinity
        batches</span
      >
    </div>
    <div class="w-fit flex items-center gap-2">
      <img [src]="peopleImg" alt="people" />
      <span class="text-xs textbf">90% students prefer infinity plan</span>
    </div>

    <div class="w-full flex items-center justify-center pb-4">
      <button
        class="text-yellow-400 caption-1 w-fit underline explore_btn"
        (click)="expoleBatchPlusTable()"
      >
        {{ upgradeData.upgradeBanner.title }}
      </button>
    </div>
  </div>

  <!-- price -->
  <section
    class="text-white flex justify-between items-center px-4 pb-4 relative"
  >
    <div class="flex flex-col gap-1.5">
      <div class="flex gap-2">
        <span class="price heading-4 bold"
          >₹ {{ upgradeData.total | getFloorValue }}</span
        >
        <del class="body-1" *ngIf="upgradeData.discount > 0">{{
          upgradeData.price | getFloorValue
        }}</del>
      </div>
      <div
        class="discount_chip px-4 py-0.5 rounded-md"
        *ngIf="upgradeData.discount > 0"
      >
        <span class="caption-1 bold"
          >{{ upgradeData.discount | getFloorValue }}% OFF</span
        >
      </div>
    </div>
    <button
      class="py-3 buy_now rounded-md body-1 semibold"
      (click)="goToOrderSummary()"
    >
      Buy Now
    </button>
  </section>
</main>
