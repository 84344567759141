import { GlobalObjectService } from './../../../../../common/services/global-object/global-object.service';
import {
  BatchStudentFee,
  CouponResModel,
} from './../../../batch-overview/batch-overview.model';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { PlansModel } from 'src/app/common/services/saarthi/saarthi.service';
import { LoaderService } from 'src/app/common/services/loader/loader.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { setPaymentMethod } from 'src/app/constants/app.constant';
import { StoreService } from 'src/app/common/services/store/store.service';
import { BatchDetailModel } from 'src/app/pages/batch/batch-overview/batch-overview.model';
import {
  PaymentService,
  CartItemsType,
} from './../../../../../common/services/payment/payment.service';
import {
  discount,
  DISABLED_DISCOUNT,
  walletIcon,
  DISABLED_WALLET,
  INFORM_ICON,
} from './../../../../../core/assets.location';
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OrderSummarySlideOverComponent } from '../order-summary-slide-over/order-summary-slide-over.component';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import {
  PaymentKeyModal,
  PaymentKeyService,
} from 'src/app/common/services/payment-key/payment-key.service';
import { lastValueFrom, Subscription } from 'rxjs';

import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectedType } from '../add-more-wrapper/add-more-wrapper.component';
import {
  BATCH_ENUMS,
  BatchService,
} from 'src/app/common/services/batch/batch.service';
import { isAfter, isBefore } from 'date-fns';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import {
  PROCEED_TO_BUY,
  DONATION_CHECKBOX_CLICK,
  PART_PAYMENT_DETAILS,
  BOOK_A_SEAT_CONTINUE,
} from 'src/app/core/analytics-events';
import { NpfService } from 'src/app/common/services/npf-service/npf-service.service';
import {
  MetaDetaVidyapeeth,
  NpfEvent,
  VIDYAPEETH_LEAD,
  VIDYAPEETH_LEAD_EVENT,
} from 'src/app/common/services/npf-service/npf.model';
import { MoengageService } from 'src/app/common/services/moengage/moengage.service';
import { CohortService } from 'src/app/common/services/cohort/cohort.service';
import { CouponService } from 'src/app/common/services/coupon/coupon.service';
import { Coupon } from 'src/app/common/services/coupon/coupon.type';
import { Donation } from 'src/app/common/services/store/store.model';
import {
  PartPaymentOptions,
  PartPaymentService,
} from 'src/app/common/services/part-payment/part-payment.service';
import {
  BatchConfig,
  BatchPlans,
} from 'src/app/common/services/batch/batch.modal';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss'],
})
export class PaymentCardComponent implements OnInit, OnDestroy {
  @Input() coming_source = '';

  walletsApplied = false;
  discountImg = discount;
  walletImg = walletIcon;
  disabledWallet = DISABLED_WALLET;
  disabledDiscount = DISABLED_DISCOUNT;
  infoImg = INFORM_ICON;
  batchDetail: BatchDetailModel;
  activePaymentKey: any;
  razorpayKey: string;
  paymentMethod: string;
  feeId: string;
  orderId: string;
  paymentStatus: string;
  afaisUrl: string;
  enterCoupon: boolean = false;
  couponCode: string = '';
  couponSub: Subscription;
  couponApplied = false;
  couponDetail: CouponResModel;
  selectedSubs: Subscription;
  selectedIds: SelectedType[] = [];
  saarthiPlan: PlansModel;
  saarthiPlanSub: Subscription;
  isWalletEnabled: boolean = false;
  isWalletEnabledSub: Subscription;
  cartItemSubs: Subscription;
  cartItems: CartItemsType[];
  maxWalletPoint: number = 0;
  maxWalletPointSub: Subscription;
  userInfo: any;
  addressEditedSub: Subscription;
  isRegistrationEndedSubs: Subscription;
  isRegistrationEnded: boolean;
  userSub: Subscription;
  coming_from = '';
  userSegmentGA: string;
  batchConfig: BatchConfig = new BatchConfig({});
  paymentDetail: any = {
    price: 0,
    discount: 0,
    deliveryCharge: 0,
  };

  totalAmount = 0;
  couponDisc = 0;

  donationDetail: Donation = {
    isDonationDefaultSelected: false,
    donationAmount: 0,
    isDonationEnabled: false,
  };
  donationDefaultValueGA: boolean = false;

  showForm: boolean = false;
  formDisplayQuery: boolean = false;
  netPrice: number;
  fromApp: boolean;
  JSBridge: any;
  formId: string = '';
  cId: string;
  cls: string;
  dept: string;
  fromCenters: boolean;
  centerName: string;
  activeCenter: string;
  cityId: string = '';
  cameFrom: string;
  batchStudentFee: BatchStudentFee;
  feeIdSub: Subscription;
  previousUrl: string = '';
  currentUrl: string = '';
  retryForOrder = '';
  processing: boolean = false;
  discountDetailName: string;
  discountDetailAmount: string;
  courseDuration: string;
  date: string;
  centerID: string;
  cityID: string;
  phase: string;
  preference: string;
  schemeId: any;
  batchId: string;
  currentCohortName: string;
  currentCohortClass: string;
  couponResponseSub: Subscription;
  couponProcessing: boolean = false;
  modeSelected = PartPaymentOptions;
  paymentTextInfo = PaymentModeText;
  selectedMode: PartPaymentOptions;
  plansData = new BatchPlans({});
  gst: number = 0;

  @ViewChild('input', { static: false })
  set input(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  isStoreItemInPlan: boolean;
  isStoreItemInPlanSubs: Subscription;
  batchDetailSub: Subscription;
  showContinueBtn: boolean = true;
  selectedPlanId: string;
  isMasterBatch: string = '';
  feeAndEMIStructureSub: Subscription;
  selectedPlanSub: Subscription;

  constructor(
    private feeBreakup: NgDialogAnimationService,
    private dialog: NgDialogAnimationService,
    private paymentService: PaymentService,
    private paymentKeyService: PaymentKeyService,
    private storeService: StoreService,
    private globalService: GlobalService,
    private showErrorService: ShowErrorService,
    private loaderService: LoaderService,
    public ngZone: NgZone,
    private router: Router,
    private storageService: StorageService,
    private batchService: BatchService,
    private activatedRoute: ActivatedRoute,

    private fireBaseService: FirebaseAnalyticsService,
    private npfService: NpfService,
    private moengageService: MoengageService,
    private cohortService: CohortService,
    private couponService: CouponService,
    private partPaymentService: PartPaymentService
  ) {
    this.userSegmentGA = this.globalService.getUserSegmentFromStorage();
    this.cameFrom =
      this.activatedRoute.snapshot.queryParamMap.get('cameFrom') || '';
    this.coming_from =
      this.activatedRoute.snapshot.queryParamMap.get('coming_from') || '';
    this.retryForOrder =
      this.activatedRoute.snapshot.queryParamMap.get('retryForOrder') || '';
    this.showForm =
      this.activatedRoute.snapshot.queryParamMap.get('showBatchForm') ===
      'true';

    this.formDisplayQuery =
      this.activatedRoute.snapshot.queryParamMap.get('showBatchForm') ===
      'true';

    this.netPrice =
      +this.activatedRoute.snapshot.queryParamMap.get('totalAmount')! || 0;
    this.cityId =
      this.activatedRoute.snapshot.queryParamMap.get('cityId') || '';

    this.fromApp =
      this.activatedRoute.snapshot.queryParamMap.get('fromApp') === 'true';
    this.fromCenters =
      this.activatedRoute.snapshot.queryParamMap.get('fromCenters') === 'true';
    this.cId = this.activatedRoute.snapshot.queryParamMap.get('cid') || '';
    this.dept = this.activatedRoute.snapshot.queryParamMap.get('dept') || '';
    this.cls = this.activatedRoute.snapshot.queryParamMap.get('cls') || '';
    this.phase = this.activatedRoute.snapshot.queryParamMap.get('phase') || '';
    this.preference =
      this.activatedRoute.snapshot.queryParamMap.get('preference') || '';
    this.schemeId =
      this.activatedRoute.snapshot.queryParamMap.get('scheme') || '';
    this.discountDetailName =
      this.activatedRoute.snapshot.queryParamMap.get('discountLabel') || '';
    this.discountDetailAmount =
      this.activatedRoute.snapshot.queryParamMap.get('discountAmount') || '';
    this.batchId =
      this.activatedRoute.snapshot.queryParamMap.get('batchId') ||
      this.batchDetail?._id ||
      '';
    this.courseDuration =
      this.activatedRoute.snapshot.queryParamMap.get('courseDuration') || '';
    this.date = this.activatedRoute.snapshot.queryParamMap.get('date') || '';
    this.currentCohortClass =
      this.activatedRoute.snapshot.queryParamMap.get('cohortClass') ||
      this.currentCohortClass;
    this.currentCohortName =
      this.activatedRoute.snapshot.queryParamMap.get('cohortName') ||
      this.currentCohortName;
    this.couponDetail = new CouponResModel({});
    this.getUser();
    this.subscriptions();
    this.selectedPlanSub =
      this.paymentService._selectedBatchPlusPlan$.subscribe(
        (res) => (this.selectedPlanId = res?._id)
      );
    this.feeAndEMIStructureSub =
      this.partPaymentService._prePurchasedFeeAndEmiStructure$.subscribe(
        (res) => {
          this.plansData = res;
        }
      );
  }

  async getBatchConfig() {
    /* fetching batch configuration using batch id */
    const batchId = this.batchDetail?._id;
    try {
      const res = await lastValueFrom(
        this.batchService.getBatchConfig(batchId)
      );
      if (res && res['data']) {
        this.batchConfig = new BatchConfig(res['data']);
        this.partPaymentService.partPaymentFeatureEnabled(
          this.batchConfig.isPartPaymentEnabled
        );
        this.selectedMode = this.batchConfig
          ?.defaultPaymentMode as PartPaymentOptions;

        if (
          this.selectedMode &&
          [this.modeSelected.EMI, this.modeSelected.FULL]?.includes(
            this.selectedMode as PartPaymentOptions
          )
        ) {
          this.partPaymentService.setPartPaymentSelectedOption(
            this.selectedMode
          );

          this.gst = this.plansData?.plans[0]?.gst;
          this.selectedMode === this.modeSelected.EMI && this.getFeeId(true);
        }
      }
    } catch (err) {
      this.showErrorService.showError(err);
      console.error(err);
    }
  }

  async ngOnInit() {
    this.saarthiPlanSub = this.paymentService.getSaarthiPlans.subscribe(
      (res) => {
        if (res.length) {
          this.saarthiPlan = res.filter(
            (saarthi: PlansModel) => saarthi.isSelected
          )[0];
        }
      }
    );

    await this.getBatchConfig();
    await this.getPaymentMethod();
    await this.updateDonationAmount();
  }

  subscriptions() {
    this.batchDetailSub = this.paymentService._batchDetail$.subscribe((res) => {
      if (res) {
        this.batchDetail = new BatchDetailModel(res);
        this.isMasterBatch = this.batchDetail?.masterBatchId as string;
      }
    });

    this.isStoreItemInPlanSubs =
      this.paymentService._isStoreItemInPlan$.subscribe((res) => {
        this.isStoreItemInPlan = res;
      });

    this.selectedSubs = this.paymentService.getSelectedIds.subscribe((res) => {
      this.selectedIds = res;
      this.cancelOrder();
    });

    this.feeIdSub = this.paymentService._feeId$.subscribe((res) => {
      if (res) {
        this.batchStudentFee = res;
        this.feeId = res._id;
      }
    });

    this.couponSub = this.couponService.coupon$.subscribe(async (code) => {
      if (code) {
        const couponSource = this.couponService.couponSource$.getValue();
        if (couponSource !== 'fbt') return;

        await this.couponClick(code);
      }
    });

    this.couponResponseSub = this.couponService.couponResponse$.subscribe(
      async (code) => {
        if (!code) {
          if (this.cameFrom === 'k8') {
            const cartAmount = this.paymentService.getCartItems
              .getValue()
              .reduce((acc: number, curr: CartItemsType) => {
                return acc + (curr.price - curr?.discount);
              }, 0);

            if (this.totalAmount !== cartAmount) this.totalAmount = cartAmount;
            if (
              this.donationDetail.isDonationEnabled &&
              this.donationDetail.donationAmount &&
              this.donationDetail.isDonationDefaultSelected
            ) {
              this.totalAmount += this.donationDetail.donationAmount;
            }
          } else {
            this.totalAmount += +this.couponDetail?.discountedAmount;
          }

          this.couponApplied = false;
          this.couponDisc = 0;
          this.couponDetail = new CouponResModel({});
        }
      }
    );

    this.addressEditedSub = this.paymentService._addressEdited$.subscribe(
      (res) => {
        this.processing = false;
        if (res) {
          this.editAddress();
        }
      }
    );

    this.maxWalletPointSub = this.paymentService._walletPoint$.subscribe(
      (res) => {
        this.walletsApplied = false;
        this.couponDisc = 0;
        let walletPoints: number = 0;
        for (let point in res) {
          walletPoints += res[point];
        }
        this.maxWalletPoint = Math.min(
          this.userInfo.profileId.wallet,
          walletPoints
        );
      }
    );

    this.cartItemSubs = this.paymentService.getCartItems.subscribe(
      async (res) => {
        this.cartItems = res;
        this.couponApplied = false;
        this.couponDisc = 0;
        this.totalAmount = 0;
        this.walletsApplied = false;
        this.couponService.setCouponCode(null);

        this.couponService.setCouponResponse(null);

        this.paymentDetail = this.cartItems.reduce((acc: any, curr) => {
          if (!acc['price']) acc['price'] = curr.price;
          else acc['price'] = acc['price'] + curr.price;

          if (!acc['discount']) acc['discount'] = curr.discount;
          else acc['discount'] = acc['discount'] + curr.discount;

          if (!acc['deliveryCharge'])
            acc['deliveryCharge'] = curr.deliveryCharge;
          else
            acc['deliveryCharge'] = acc['deliveryCharge'] + curr.deliveryCharge;
          return acc;
        }, {});
        this.updateTotalAmount();
      }
    );

    this.isWalletEnabledSub = this.paymentService._isWalletEnabled.subscribe(
      (res) => {
        if (res) {
          this.isWalletEnabled = false;
          for (const item in res) {
            if (res[item]) {
              this.isWalletEnabled = true;
              return;
            }
          }
        }
      }
    );

    this.isRegistrationEndedSubs =
      this.paymentService._isRegistrationEnded.subscribe((res) => {
        this.isRegistrationEnded = res;
      });
  }

  toggleRadioButton(mode: any) {
    if (this.selectedMode !== mode) {
      this.selectedMode = mode;
      this.paymentService.setSelectedIds([]);
      this.partPaymentService.setPartPaymentSelectedOption(mode);
      if (this.selectedMode === this.modeSelected.EMI) {
        /* Cart Price + fee Id logic  for EMI */
        this.getFeeId(true);
      } else if (this.selectedMode === this.modeSelected.FULL) {
        /* Cart Price + fee Id logic  for FULL Mode */
        this.getFeeId(false);
      }
    }
  }

  logPartPaymentDetailEvent(source: string = '') {
    const eventData = {
      user_type:
        this.globalService.getIsPathShalaFromStorage() === 'true'
          ? 'offline'
          : 'online',
      user_segment: this.userSegmentGA,
      isthis_batch_premium: this.batchDetail?.masterBatchId ? 'yes' : 'no',
      source,
      is_plus_enabled: this.batchDetail?.isBatchPlusEnabled ? 'yes' : 'no',
      default_payment_mode:
        this.selectedMode === this.modeSelected.EMI
          ? 'part_payment'
          : 'full_payment',
      batch_id: this.batchDetail?._id,
      batch_name: this.batchDetail?.name,
    };
    this.fireBaseService.logEvents(
      PART_PAYMENT_DETAILS,
      eventData,
      false,
      true,
      true,
      true
    );
  }

  openEMIAndFeeStructureOverLay(event?: MouseEvent) {
    this.logPartPaymentDetailEvent('order_details_i_button');
    this.partPaymentService.openModal(false, event, this.plansData);
  }

  getUser() {
    this.userSub = this.globalService.getUser().subscribe((res) => {
      if (res) {
        this.userInfo = res;
      } else {
        this.userInfo = {};
      }
    });
  }

  updateTotalAmount() {
    this.totalAmount =
      +this.paymentDetail?.price - +this.paymentDetail?.discount;

    if (this.paymentDetail?.deliveryCharge)
      this.totalAmount += +this.paymentDetail?.deliveryCharge;
    if (
      this.donationDetail.isDonationEnabled &&
      this.donationDetail.donationAmount &&
      this.donationDetail.isDonationDefaultSelected
    ) {
      this.totalAmount += this.donationDetail.donationAmount;
    }

    if (this.walletsApplied) this.totalAmount -= this.maxWalletPoint;

    if (this.totalAmount < 0) this.totalAmount = 0;

    if (this.totalAmount === 0) this.isWalletEnabled = false;
  }
  async updateDonationAmount() {
    const query = {
      type: 'BATCH',
      typeId: this.batchDetail._id,
      sourcePage: 'BATCHES',
    };
    try {
      const res = await lastValueFrom(
        this.storeService.getDonationAmount(query)
      );
      if (res && res['data']) {
        this.donationDetail.isDonationDefaultSelected =
          res['data']['isDefaultSelected'] || false;
        this.donationDetail.donationAmount = res['data']['donationAmount'] || 0;
        this.donationDetail.isDonationEnabled =
          res['data']['isDonationEnabled'] || false;
        this.donationDefaultValueGA = res['data']['isDefaultSelected'] || false;
      }
      this.updateTotalAmount();
    } catch (error) {
      console.log(error);
    }
  }
  isDonationChecked(event: boolean) {
    this.donationDetail.isDonationDefaultSelected = event;
    if (event) {
      this.totalAmount += this.donationDetail.donationAmount;
    } else this.totalAmount -= this.donationDetail.donationAmount;
    this.donationCheckboxClickEvent(event);
  }
  checkDonationEnabledForFreeBatch(): boolean {
    return (
      this.donationDetail.isDonationEnabled &&
      this.donationDetail.isDonationDefaultSelected
    );
  }

  donationCheckboxClickEvent(event: boolean) {
    const data = {
      action: event,
      default_status: this.donationDefaultValueGA,
      user_type:
        this.globalService.getIsPathShalaFromStorage() === 'true'
          ? 'offline'
          : 'online',
      source: 'batch_order_summary_page',
    };
    this.fireBaseService.logEvents(
      DONATION_CHECKBOX_CLICK,
      data,
      true,
      true,
      false,
      true,
      false
    );
  }

  ngOnDestroy(): void {
    this.selectedSubs?.unsubscribe();
    this.isWalletEnabledSub?.unsubscribe();
    this.maxWalletPointSub?.unsubscribe();
    this.saarthiPlanSub?.unsubscribe();
    this.isRegistrationEndedSubs?.unsubscribe();
    this.userSub?.unsubscribe();
    this.couponSub?.unsubscribe();
    this.couponResponseSub?.unsubscribe();
    this.couponService.reset();
    this.isStoreItemInPlanSubs?.unsubscribe();
    this.batchDetailSub?.unsubscribe();
    this.feeAndEMIStructureSub?.unsubscribe();
    this.selectedPlanSub?.unsubscribe();
  }

  applyWattetPoints() {
    if (this.walletsApplied) {
      this.walletsApplied = false;
      this.totalAmount += this.maxWalletPoint;
      this.couponDisc -= this.maxWalletPoint;
    } else {
      this.walletsApplied = true;
      this.totalAmount -= this.maxWalletPoint;
      this.couponDisc += this.maxWalletPoint;
      this.globalService.setShowToast(true);
    }
  }

  async addItemsToCart() {
    const batchObj = {
      type: 'BATCH',
      typeId: this.feeId,
    };

    const isPlusPlanPurchased =
      this.batchService._isPlusPlanPurchased$.getValue();

    const ids = this.selectedIds.map((item: SelectedType) => item.typeId);
    if (!ids.includes(batchObj.typeId) && !this.batchDetail.isPurchased) {
      this.selectedIds.push(batchObj);
    }

    let itemsInCart: any[] = [];

    const cartItems = await this.getCartItems();

    if (cartItems.length) {
      itemsInCart = this.selectedIds.filter((item) => {
        const found = cartItems.find((i: any) => i.typeId === item.typeId);
        if (!found) return item;
        return null;
      });
    } else {
      itemsInCart = this.selectedIds;
    }

    try {
      const cartItems = itemsInCart.map(
        async (item) => await this.createOrder(item)
      );

      return cartItems;
    } catch (err) {
      this.showErrorService.showError(err);
      return;
    }
  }

  async getCartItems() {
    try {
      return await lastValueFrom(this.storeService.getCart());
    } catch (err) {
      console.log(err);
      return;
    }
  }

  async couponClick(code: Coupon) {
    const data: any = {
      ...(code?.couponCode?.length > 0 && { couponCode: code.couponCode }),
      ...(code?._id?.length > 0 && { couponId: code._id }),
      type: 'CART',
    };

    data['typeIds'] = this.selectedIds.map((item) => item.typeId);

    if (!data['typeIds'].includes(this.feeId) && this.feeId.length > 0) {
      data['typeIds'] = [...data['typeIds'], this.feeId];
    }
    this.couponProcessing = true;
    const response = await this.addItemsToCart();

    if (response)
      Promise.all(response)
        .then(async (_res: any) => {
          const res: any = await lastValueFrom(
            this.storeService.applyCoupon(data)
          );

          if (res && res['data']) {
            this.couponService.setCouponResponse(res['data']);
            this.couponApplied = true;
            this.couponDetail = new CouponResModel(res['data']);

            if (
              this.couponDetail.refereeAmountType.toLowerCase() !== 'cashback'
            ) {
              this.totalAmount -= +this.couponDetail?.discountedAmount;
              this.couponDisc = +this.couponDetail?.discountedAmount;
            }
            this.couponService.setCouponProcessing(false);
          }
        })
        .catch(async (err) => {
          this.showErrorService.showError(err);
          this.couponService.setCouponCode(null);
          this.couponService.setCouponProcessing(false);
        })
        .finally(() => {
          this.couponProcessing = false;
        });
  }

  async getFeeId(partPayment: boolean = false) {
    const query = {
      batchAmount: this.batchDetail?.fee?.amount,
      partPayment,
      planId: this.selectedPlanId,
    };

    try {
      const res = await lastValueFrom(
        this.batchService.getStudentFeeId(this.batchDetail?._id, query)
      );
      if (res) {
        this.paymentService.setFeeId(res);
      }
    } catch (err) {
      this.showErrorService.showError(err);
    }
  }

  async getPaymentMethod() {
    try {
      const res = await lastValueFrom(this.storeService.getPaymentMethod());
      if (res && res['data']) {
        if (res['data']['juspay']) {
          this.paymentMethod = 'JUSPAY';
        } else {
          this.paymentMethod = 'RAZOR_PAY';
          this.getPaymentKeys();
        }
      }
    } catch (e) {
      this.paymentMethod = 'RAZOR_PAY';
      this.getPaymentKeys();
    } finally {
      setPaymentMethod(this.paymentMethod);
    }
  }

  async getPaymentKeys() {
    const keysData: PaymentKeyModal[] =
      (await this.paymentKeyService.getPaymentKeys()) || [];

    if (keysData.length > 0) {
      keysData.forEach((item) => {
        if (item.name === 'two') {
          this.razorpayKey = item.id;
          return;
        }
      });
    } else {
      this.razorpayKey = keysData[0].id;
    }
  }
  handleBatchEnrollment(
    batchService: BatchService,
    batchDetail: BatchDetailModel,
    showModal: boolean,
    batchNavigation: boolean
  ) {
    batchService.enrollFreeBatch(
      batchDetail?._id,
      batchDetail?.name,
      showModal,
      batchNavigation
    );
  }

  handleLeadFiltration(onSuccessCB: () => void, onCloseCB = () => {}) {
    this.batchService.openLeadFiltrationForm({
      formId: this.batchDetail?.formId,
      batchId: this.batchDetail?._id,
      onSuccessCB,
      onCloseCB,
    });
  }

  shouldLeadFiltrationFormOpen = (batchDetails: BatchDetailModel) => {
    return (
      batchDetails?.fee?.total == 0 &&
      batchDetails?.formId &&
      (!batchDetails?.config?.defaultAddon ||
        batchDetails?.config?.defaultAddon?.length === 0) &&
      (!batchDetails?.config?.defaultFbt ||
        batchDetails?.config?.defaultFbt?.length === 0)
    );
  };

  async batchFormSubmit(event: any) {
    const { formId, isEnrolled } = event;
    if (formId?.length > 0) {
      this.formId = formId;
    }
    if (
      this.batchDetail &&
      this.batchDetail._id.length > 0 &&
      this.batchStudentFee &&
      !isEnrolled
    ) {
      setTimeout(async () => {
        await this.payNow();
      }, 400);
    }
    if (isEnrolled) {
      if (this.shouldLeadFiltrationFormOpen(this.batchDetail)) {
        this.handleLeadFiltration(() => {
          this.handleBatchEnrollment(
            this.batchService,
            this.batchDetail,
            false,
            false
          );
          this.router.navigate(['/batches/study/my-batches'], {
            queryParams: { activeTab: 'Free' },
          });
        });
      } else {
        this.handleBatchEnrollment(
          this.batchService,
          this.batchDetail,
          false,
          false
        );
        this.router.navigate(['/batches/study/my-batches'], {
          queryParams: { activeTab: 'Free' },
        });
      }
    }
  }

  closeForm(event: boolean) {
    if (event) {
      this.showForm = false;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { showBatchForm: false },
        queryParamsHandling: 'merge',
      });
    }
  }

  logBookASeatEvent() {
    const eventData = {
      user_cohort_id: this.cohortService.getCohortIdFromLocal(),
      user_type:
        this.globalService.getIsPathShalaFromStorage() === 'true'
          ? 'offline'
          : 'online',
      user_segment: this.userSegmentGA,
      isthis_batch_premium: this.batchDetail?.masterBatchId ? 'yes' : 'no',
      default_payment_mode:
        this.selectedMode === this.modeSelected.EMI
          ? 'part_payment'
          : 'full_payment',
      batch_id: this.batchDetail?._id,
      batch_name: this.batchDetail?.name,
      donation_amount: this.donationDetail.donationAmount,
      is_donation_on: this.donationDetail.isDonationEnabled,
    };
    this.fireBaseService.logEvents(
      BOOK_A_SEAT_CONTINUE,
      eventData,
      false,
      true,
      true,
      true
    );
  }

  async onButtonClick(event?: MouseEvent) {
    this.getUserSegment();
    this.logEventMoengage();
    if (this.coming_source === 'afterPayment') {
      const afOrderId =
        this.activatedRoute.snapshot.queryParamMap.get('order_id') || '';
      this.storageService.setAfterPaymentOrderId(afOrderId);
    }

    const cartData = this.paymentService.getCartItems.getValue() || [];
    if (cartData.length === 0) {
      this.globalService.showSnackBar('Please add an item to proceed');
      return;
    }

    if (this.processing) return;

    if (this.isRegistrationEnded) {
      this.globalService.showSnackBar('Batch Registration Has Ended', 'OK');
      return;
    }

    const countryCode = this.userInfo['countryCode'];
    const mobileNumber = this.userInfo['primaryNumber'];
    if (
      this.batchDetail.config.isFormEnabled &&
      (countryCode === '+91' || mobileNumber.length === 10)
    ) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          showBatchForm: 'true',
          cId: this.cId || this.centerID,
          cityId: this.cityId || this.cityID,
          phase: this.phase || '',
          scheme: this.schemeId || '',
          preference: this.preference || '',
          fromCenters: this.fromCenters,
          cohortName: this.currentCohortName || this.batchDetail.exam,
          cohortClass: this.currentCohortClass || this.batchDetail.class,
          cls: this.cls || this.batchDetail.class,
          dept: this.dept || this.batchDetail.exam,
          netPrice: this.totalAmount,
          batchId: this.batchId || this.batchDetail?._id,
          courseDuration: this.courseDuration,
          date: this.date,
          discountLabel: this.discountDetailName,
          discountAmount: this.discountDetailAmount,
        },
      });
      this.showForm = true;
      this.netPrice = this.totalAmount;
      return;
    }

    if (this.selectedMode === this.modeSelected.EMI) {
      this.logPartPaymentDetailEvent('proceed_to_buy');
      this.partPaymentService.openModal(true, event, this.plansData, () => {
        this.showForm = true;
        this.netPrice = this.totalAmount;
        this.logBookASeatEvent();
      });
    } else {
      if (this.selectedMode === this.modeSelected.FULL) {
        this.showForm = true;
        this.netPrice = this.totalAmount;
        return;
      }
      await this.payNow();
    }
  }

  async payNow() {
    this.processing = true;
    const serverTime = new Date();
    const regStartDate = new Date(this.batchDetail.registrationStartDate);
    const regEndDate = new Date(this.batchDetail.registrationEndDate);

    if (isAfter(serverTime, regEndDate)) {
      this.globalService.showSnackBar('Batch Registration has ended', 'Ok');
      return;
    } else if (isBefore(serverTime, regStartDate)) {
      this.globalService.showSnackBar(
        'Batch Registration has not yet started',
        'OK'
      );
      return;
    }

    if (this.batchDetail?.fee?.total === 0 && !this.batchDetail.isPathshala) {
      if (this.selectedIds.length === 0) {
        if (this.checkDonationEnabledForFreeBatch()) {
          this.globalService.showSnackBar(
            'Please untick donation checkbox to continue'
          );
          this.processing = false;
          return;
        }

        if (this.shouldLeadFiltrationFormOpen(this.batchDetail)) {
          this.handleLeadFiltration(() =>
            this.handleBatchEnrollment(
              this.batchService,
              this.batchDetail,
              true,
              true
            )
          );
        } else {
          await this.batchService.enrollFreeBatch(
            this.batchDetail?._id,
            this.batchDetail?.name,
            true,
            true
          );
        }
        return;
      } else {
        if (this.shouldLeadFiltrationFormOpen(this.batchDetail)) {
          this.handleLeadFiltration(() =>
            this.handleBatchEnrollment(
              this.batchService,
              this.batchDetail,
              false,
              false
            )
          );
        } else {
          await this.batchService.enrollFreeBatch(
            this.batchDetail?._id,
            this.batchDetail?.name,
            false
          );
        }
      }
    }

    await this.getPaymentMethod();

    const shouldAddressModalOpen = this.selectedIds.some(
      (item) => item.type === 'BOOK' || item.type === 'STORE_ITEM'
    );

    const hasStoreItem = this.paymentService._selectedCombo$
      .getValue()
      .items.findIndex((item) => item.type === 'BOOK');

    const userAddress = this.userInfo?.profileId?.address;
    let isAdderssOpen = true;

    if (
      userAddress &&
      userAddress['landmark']?.length > 0 &&
      userAddress['pincode']?.toString()?.length > 0 &&
      userAddress['state']?.length > 0 &&
      userAddress['city']?.length > 0 &&
      userAddress['line1']?.length > 0
    ) {
      isAdderssOpen = false;
    }

    const isAddress =
      shouldAddressModalOpen || hasStoreItem > -1 ? true : false;
    if (this.coming_from !== 'afterpayment') {
      this.proceedToPayGA(isAddress);
    }
    if (
      (shouldAddressModalOpen && isAdderssOpen) ||
      (hasStoreItem > -1 && isAdderssOpen) ||
      (this.isStoreItemInPlan && isAdderssOpen)
    ) {
      this.editAddress();
      return;
    }

    await this.pay();
  }

  async pay() {
    const cartData = this.paymentService.getCartItems.getValue() || [];
    this.storageService.setCartSelectedData(cartData);
    if (this.selectedIds.length > 0) {
      const ifBatchInSelectedId = this.selectedIds.findIndex(
        (item) => item.type === 'BATCH'
      );
      const isPlusPlanPurchased =
        this.batchService._isPlusPlanPurchased$.getValue();
      if (
        this.batchDetail?.fee?.amount > 0 &&
        ifBatchInSelectedId < 0 &&
        !this.batchDetail.isPurchased
      ) {
        this.selectedIds.push({
          type: 'BATCH',
          typeId: this.feeId,
        });
      }

      this.payWithFBT();

      return;
    } else {
      if (this.batchDetail?.fee?.amount > 0 && !this.batchDetail.isPurchased) {
        this.selectedIds.push({
          type: 'BATCH',
          typeId: this.feeId,
        });
      }
      const cartItems = await this.addItemsToCart();
    }

    const orderData: any = {
      name: this.batchDetail?.name,
      feeMapId: this.feeId,
      type: 'BATCH',
      orderByPage:
        this.coming_source === 'afterPayment' ? 'thank_you' : 'order_summary',
      ...(this.couponApplied && {
        ...(this.couponDetail?.coupon?.couponCode && {
          couponCode: this.couponDetail?.coupon?.couponCode,
        }),
        ...(this.couponDetail?.coupon?._id && {
          couponId: this.couponDetail?.coupon?._id,
        }),
      }),
      ...(this.donationDetail.isDonationDefaultSelected && {
        donationAmount: this.donationDetail.donationAmount,
      }),
    };

    if (this.cameFrom === BATCH_ENUMS.UPGRADE_BANNER) {
      orderData['orderByPage'] = BATCH_ENUMS.UPGRADE_BANNER;
    }

    if (this.totalAmount === 0) {
      orderData['paymentSource'] = 'WALLET';
    } else {
      orderData['paymentSource'] = this.paymentMethod;
    }

    if (this.walletsApplied) {
      orderData['wallet'] = this.maxWalletPoint;
    }

    if (this.formId.length > 0) {
      orderData['formId'] = this.formId;
    }

    if (this.coming_from === 'afterpayment') {
      orderData['isRetry'] = true;
    }
    if (this.retryForOrder) {
      orderData['retryForOrder'] = this.retryForOrder;
    }

    if (this.paymentMethod === 'JUSPAY') {
      orderData['client'] = 'web';
      orderData['returnHost'] = this.globalService.domainAndApp;
    } else if (this.paymentMethod === 'RAZOR_PAY') {
      orderData['paymentKey'] = this.activePaymentKey;
    }

    if (this.isStoreItemInPlan) {
      const user = this.paymentService.getDeliveryAddress.getValue();
      if (user) {
        const address = user.profileId?.address;
        address['phone'] = user?.primaryNumber;
        address['name'] = `${user?.firstName} ${user?.lastName}`;

        orderData['address'] = address;
      }
    }

    try {
      const res: any = await lastValueFrom(
        this.storeService.createOrder(orderData)
      );
      if (res && res['data']) {
        this.orderId = res['data']._id;
        this.paymentService.setOrderId(this.orderId);

        this.globalService.setPaymentDetails({
          afprice: this.batchDetail.fee.total,
          orderId: this.orderId,
          coursename: this.batchDetail.name,
        });
        if (this.coming_from === 'afterpayment') {
          this.thankuPageGA(this.orderId);
        }
        switch (res['data']['paymentSource']) {
          case 'JUSPAY':
            this.juspay(res['data']);
            break;
          case 'RAZOR_PAY':
            this.razorPay(res['data']['response']);
            break;
          case 'WALLET':
            const response: any = await lastValueFrom(
              this.storeService.payOrderByPoints(this.orderId)
            );

            if (response.success) {
              this.userInfo['profileId']['wallet'] =
                this.userInfo['profileId']['wallet'] - this.maxWalletPoint;
              this.globalService.setUser(this.userInfo);
              await this.successCallback(true);
            } else {
              await this.failureCallBack(true);
            }
            break;
          default:
        }
      }
    } catch (err) {
      this.showErrorService.showError(err);
    } finally {
      setTimeout(() => {
        this.processing = false;
      }, 5000);
    }
  }

  thankuPageGA(orderId: string) {
    const data: any = {
      came_from: 'thankyou_page',
      order_amount: this.totalAmount,
      order_id_of_thanku_page: orderId,
      user_cohort_id: this.cohortService.getCohortIdFromLocal(),
      donation_amount: this.donationDetail.donationAmount,
      is_donation_on: this.donationDetail.isDonationEnabled,
    };

    this.fireBaseService.logEvents(PROCEED_TO_BUY, data, true);
  }

  proceedToPayGA(isAddress: boolean) {
    const res = this.selectedIds.findIndex(
      (item: SelectedType) => item.type === 'FBT_PACKAGE'
    );

    const cartItems = this.selectedIds
      .map((item: SelectedType) => item?.typeId)
      .join(',');
    const planData = this.storageService.getComparisonPlanData();

    const defaultPlan = planData?.plans?.find(
      (plan: any) => plan?.isRecommended
    );

    const selectedPlanId = this.batchService._batchPlanId$.getValue();
    const selectedPlan = planData?.plans?.find(
      (plan: any) => plan?._id === selectedPlanId
    );
    const currentPlan = planData?.plans?.find(
      (plan: any) => plan?.isPlanPurchased
    );

    const data = {
      batch_id: this.batchDetail?._id,
      batch_name: this.batchDetail?.name,
      class: this.userInfo?.profileId?.class,
      exam: this.userInfo?.profileId?.exams[0],
      address_required: isAddress ? 'yes' : 'no',
      address_selected: this.userInfo?.profileId?.address ? 'yes' : 'no',
      fbt_available: res >= 0 ? 'yes' : 'no',
      isCouponApplicable: this.couponService.isCouponApplicable$.getValue(),
      cartItems,
      donation_amount: this.donationDetail.donationAmount,
      is_donation_on: this.donationDetail.isDonationEnabled,
      is_part_payment_enabled: this.selectedMode ? 'yes' : 'no',
      is_plus_enabled: this.batchDetail?.isBatchPlusEnabled ? 'yes' : 'no',
      isthis_batch_premium: this.batchDetail?.masterBatchId ? 'yes' : 'no',
      plan_selected: selectedPlan ? selectedPlan?.title : defaultPlan?.title,
      current_plan: currentPlan?.title || '',
      planId: selectedPlanId,
      ...(this.selectedMode
        ? {
            default_payment_mode:
              this.selectedMode === this.modeSelected.EMI
                ? 'part_payment'
                : 'full_payment',
          }
        : {}),
    };

    this.fireBaseService.logEvents(PROCEED_TO_BUY, data, true);
  }

  logEventMoengage() {
    const bookName = this.cartItems.filter(
      (items: any) => items.type == 'book'
    );
    const testSeriesName = this.cartItems.filter(
      (items: any) => items.type == 'test_category_mode'
    );
    const saarthi = this.cartItems.filter(
      (items: any) => items.type == 'saarthi'
    );

    const storeItems = this.cartItems.filter(
      (item: any) => item.type == 'store_item'
    );

    const combo = this.cartItems.filter(
      (item: any) => item.type === 'FBT_PACKAGE'
    );

    this.globalService.setCartData({
      bookData: bookName,
      testSeriesData: testSeriesName,
      saarthiData: saarthi,
      storeItems: storeItems,
      comboStatus: combo,
      amount: this.totalAmount,
      discount: this.paymentDetail.discount,
      cartData: this.cartItems.length,
    });

    this.moengageService.trackMoengageEvent(PROCEED_TO_BUY, {
      batch_id: this.batchDetail?._id || '',
      batch_name: this.batchDetail?.name || '',
      batch_type: this.batchDetail?.type || '',
      batch_price: this.batchDetail?.fee?.total || '',
      batch_discount: this.batchDetail?.fee?.discount || '',
      saarthi_name: saarthi[0]?.name || '',
      saarthi_price: saarthi[0]?.price || '',
      saarthi_discount: saarthi[0]?.discount || '',
      khazana_available: this.batchDetail?.khazanaProgramId ? 'yes' : 'no',
      class: this.userInfo?.profileId?.class || '',
      exam: this.userInfo?.profileId?.exams || '',
      order_id: this.orderId || '',
      cohort_name:
        `${this.userInfo?.profileId?.exams[0]}-${this.userInfo?.profileId?.class}` ||
        '',
      coupon_code: this.couponDetail?.coupon?.couponCode || '',
      coupon_applied: this.couponApplied ? 'True' : 'False',
      book_name: bookName[0]?.name || storeItems[0]?.name || '',
      book_price: bookName[0]?.price || storeItems[0]?.price || '',
      test_series_name: testSeriesName[0]?.name || '',
      test_series_price: testSeriesName[0]?.price || '',
      test_id: testSeriesName[0]?.id || '',
      price: this.totalAmount || '',
      discount: this.paymentDetail.discount || '',
      combo: this.cartItems.length >= 2 ? 'yes' : 'no',
    });
  }

  async payWithFBT() {
    const cartItems = await this.addItemsToCart();

    if (cartItems) {
      Promise.all([...cartItems])
        .then(async (val) => {
          await this.payForFBT();
        })
        .catch((err) => {
          this.showErrorService.showError(err);
        })
        .finally(() => {
          setTimeout(() => {
            this.processing = false;
          }, 5000);
        });
    }
  }

  async payForFBT() {
    const message = 'Taking you to payment gateway. Please wait...';

    const orderData: any = {
      paymentSource: this.paymentMethod,
      name: this.batchDetail.name,
      orderByPage:
        this.coming_source === 'afterPayment' ? 'thank_you' : 'order_summary',
      ...(this.couponApplied && {
        ...(this.couponDetail?.coupon?.couponCode && {
          couponCode: this.couponDetail?.coupon?.couponCode,
        }),
        ...(this.couponDetail?.coupon?._id && {
          couponId: this.couponDetail?.coupon?._id,
        }),
      }),
      ...(this.donationDetail.isDonationDefaultSelected && {
        donationAmount: this.donationDetail.donationAmount,
      }),
    };

    if (this.cameFrom === BATCH_ENUMS.UPGRADE_BANNER) {
      orderData['orderByPage'] = BATCH_ENUMS.UPGRADE_BANNER;
    }

    if (this.paymentMethod === 'JUSPAY') {
      orderData['client'] = 'web';
      orderData['returnHost'] = this.globalService.domainAndApp;
    } else if (this.paymentMethod === 'RAZOR_PAY') {
      orderData['paymentKey'] = this.razorpayKey;
    }

    if (this.walletsApplied) {
      orderData['wallet'] = this.maxWalletPoint;
    }

    if (this.formId.length > 0) {
      orderData['formId'] = this.formId;
    }
    if (this.retryForOrder) {
      orderData['retryForOrder'] = this.retryForOrder;
    }
    if (this.coming_from === 'afterpayment') {
      orderData['isRetry'] = true;
    }
    if (this.retryForOrder) {
      orderData['retryForOrder'] = this.retryForOrder;
    }

    const isAddressApplicable = this.selectedIds.find(
      (item) =>
        item.type.toLowerCase().includes('book') ||
        item.type.toLowerCase().includes('store')
    );

    const hasStoreItem = this.paymentService._selectedCombo$
      .getValue()
      .items.findIndex((item) => item.type === 'BOOK');

    if (isAddressApplicable || hasStoreItem > -1 || this.isStoreItemInPlan) {
      const user = this.paymentService.getDeliveryAddress.getValue();
      if (user) {
        const address = user.profileId?.address;
        address['phone'] = user?.primaryNumber;
        address['name'] = `${user?.firstName} ${user?.lastName}`;

        orderData['address'] = address;
      }
    }

    try {
      const order: any = await lastValueFrom(
        this.storeService.createOrder(orderData)
      );

      this.orderId = order['data']._id;

      this.globalService.setPaymentDetails({
        afprice: this.batchDetail.fee.total,
        orderId: this.orderId,
        coursename: this.batchDetail.name,
      });

      switch (order['data']['paymentSource']) {
        case 'RAZOR_PAY':
          await this.razorPay(order['data'].response);
          break;
        case 'JUSPAY':
          await this.juspay(order['data']);
          break;
        default:
      }
    } catch (e) {
      await this.cancelOrder();
      await this.showErrorService.showError(e);
    } finally {
      await this.loaderService.unloadData(message);
      setTimeout(() => {
        this.processing = false;
      }, 5000);
    }
  }

  async createOrder(data: any) {
    const message = 'Adding Items to Cart. Please wait...';
    try {
      return await lastValueFrom(this.storeService.addToCartSingle(data));
    } catch (e: any) {
      if (
        e &&
        e.error &&
        e.error.message &&
        e.error.message === 'Item already exist in cart'
      ) {
        console.log(e);
      } else {
        await this.showErrorService.showError(e);
      }
      return;
    } finally {
      this.loaderService.unloadData(message);
    }
  }

  async juspay(order: any) {
    const couponDetails = {
      isCouponApplicable: this.couponService.isCouponApplicable$.getValue(),
      coupon_code: this.couponDetail?.coupon?.couponCode || '',
      coupon_applied: this.couponApplied,
      isbrand_coupon: this.couponDetail?.coupon?.offerType === 'MARKETING',
    };

    this.storageService.setValue('COUPON-DETAILS', couponDetails, 'json');
    const message = 'Creating order. Please wait...';
    await this.loaderService.unloadData(message);
    this.globalService.juspay$.next(order);
  }

  async razorPay(res: any) {
    const userInfo = this.globalService.getUser().getValue();
    const options: any = {
      description: '',
      currency: 'INR',
      key: this.activePaymentKey.id,
      order_id: res.id,
      amount: res.amount,
      name: this.batchDetail.name,
      prefill: {
        email: userInfo.email ? userInfo.email : '',
        contact: userInfo.primaryNumber,
        name: userInfo.lastName
          ? userInfo.firstName + ' ' + userInfo.lastName
          : userInfo.firstName,
      },
    };

    options['handler'] = (response: any) => this.handler(response, options);

    const rzpay = new this.storeService.nativeWindow.Razorpay(options);
    rzpay.open();
  }

  async handler(response: any, options: any) {
    if (response) {
      this.successCallback();
      await this.openPopover(
        'payment_successful',
        options.amount,
        options.name
      );
    } else {
      // this.failureCallBack();
      await this.openPopover('payment_unsuccessful');
    }
  }

  async openPopover(type: any, amount?: any, name?: any) {
    this.paymentStatus = type;
    if (type === 'payment_successful') {
      this.afaisUrl = `https://physicswallah.g2afse.com/success.php?offer_id=${
        this.orderId
      }&afid=${this.batchDetail?._id}&afprice=${
        amount / 100
      }&afstatus=1&custom_field1=${amount / 100}&custom_field2=${name}`;
      this.successCallback();
    } else {
      this.failureCallBack();
    }
  }

  async successCallback(walletApplied?: boolean) {
    this.loaderService.unloadData('Creating order. Please wait...');
    this.ngZone.run(() => {
      if (walletApplied) {
        this.router.navigate(['after-payment'], {
          queryParams: {
            order_id: this.orderId,
            wallet_applied: walletApplied,
            responseStatus: 'SUCCESS',
          },
        });
      } else {
        this.router.navigate(['after-payment'], {
          queryParams: {
            order_id: this.orderId,
          },
        });
      }
    });

    this.loaderService.unloadData('Creating order. Please wait...');
  }

  async failureCallBack(walletApplied?: boolean) {
    // Payment Failure
    this.globalService.setPaymentRedirectionUrl(this.router.url);

    this.loaderService.unloadData('Creating order. Please wait...');

    this.ngZone.run(() => {
      this.router.navigate(['after-payment'], {
        queryParams: {
          order_id: this.orderId,
          wallet_applied: walletApplied,
          responseStatus: 'FAIL',
        },
      });
    });
  }

  editAddress() {
    const dialogRef = this.dialog.open(OrderSummarySlideOverComponent, {
      panelClass: 'mahapack-side-dialog',
      position: { right: '0px' },
      disableClose: false,
      data: {
        title: 'Add Delivery Address',
        activeType: 'address',
      },
      animation: {
        to: 'left',
        incomingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
        outgoingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      this.paymentService.setAddressEdited(false);
      this.processing = false;
      if (res && res.length > 0 && res === 'close_payment') {
        if (
          this.formId.length === 0 &&
          this.batchDetail.config.isFormEnabled &&
          (this.userInfo['countryCode'] === '+91' ||
            this.userInfo['primaryNumber'].length === 10)
        ) {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
              showBatchForm: 'true',
              cId: this.cId,
              cityId: this.cityId,
              fromCenters: this.fromCenters,
              cls: this.cls,
              dept: this.dept,
              netPrice: this.totalAmount,
            },
          });

          this.showForm = true;
          this.netPrice = this.totalAmount;
          return;
        }

        await this.pay();
      }
    });
  }

  async cancelOrder() {
    await lastValueFrom(this.storeService.emptyCart());
    const orderId = this.paymentService._orderId$;

    if (orderId?.length) {
      await lastValueFrom(this.storeService.cancelOrder(orderId));
    }
  }

  async getUserSegment() {
    const isPathShalaUser =
      await this.globalService.getIsPathShalaFromStorage();

    if (isPathShalaUser === 'false' && this.batchDetail.isPathshala) {
      this.vidyapeethEvent(
        VIDYAPEETH_LEAD_EVENT.PAY_NOW,
        VIDYAPEETH_LEAD_EVENT.PAYMENT_INITIATED
      );
    }
  }

  async vidyapeethEvent(activity: string, paymentStatus: string) {
    this.userInfo = this.globalService.getUser().getValue() || {};
    const metaVidyapeethData: MetaDetaVidyapeeth = {
      name: `${this.userInfo?.firstName}  ${this.userInfo?.lastName}` || '',
      email: this.userInfo?.email || '',
      countryDialCode: this.userInfo?.countryCode || '',
      exam: this.currentCohortName || '',
      gender: this.userInfo?.profileId?.gender || '',
      centerName: this.cId || '',
      batchName: this.batchDetail?.name || '',
      courseDuration: '',
      batch_offering: this.batchDetail?.config?.offlineOfferingbatchTag || '',
      class: this.currentCohortClass || '',
      paymentStatus: paymentStatus || '',
      gaurdianName: '',
      gaurdianMobileNumber: '',
    };
    const vidyaPeethEvent: NpfEvent = {
      eventName: VIDYAPEETH_LEAD,
      lastActivity: activity,
      eventMetaData: metaVidyapeethData,
    };

    try {
      const res: any = await lastValueFrom(
        this.npfService.postVidyapeethLeadEvent(vidyaPeethEvent)
      );
    } catch (error) {
      console.log(error, 'error');
    }
  }
}

export enum PaymentModeText {
  EMI = 'Seat booking fee',
  FULL = 'Full Payment',
}
