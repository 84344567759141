export enum EntryComponentName {
  BATCH_LECTURE_VIDEOS = 'BATCH_LECTURE_VIDEOS',
  BATCH_DPP_VIDEOS = 'BATCH_DPP_VIDEOS',
  BATCH_QUIZ_SOL_VIDEOS = 'BATCH_QUIZ_SOL_VIDEOS',
  BATCH_TODAYS_CLASS_VIDEOS = 'BATCH_TODAYS_CLASS_VIDEOS',
  BATCH_DEMO_VIDEOS = 'BATCH_DEMO_VIDEOS',
  RECENTLY_WATCHED_VIDEOS = 'RECENTLY_WATCHED_VIDEOS',
  SCHEDULED_CLASS_VIDEOS = 'SCHEDULED_CLASS_VIDEOS',
  CONTINUE_LEARNING_VIDEOS = 'CONTINUE_LEARNING_VIDEOS',
  TEST_SERIES_VIDEOS = 'TEST_SERIES_VIDEOS',
  BATCH_PREVIEW_VIDEOS = 'BATCH_PREVIEW_VIDEOS',
  LIBRARY_QUIZ_VIDEOS = 'LIBRARY_QUIZ_VIDEOS',
  KHAZANA_LECTURE_VIDEOS = 'KHAZANA_LECTURE_VIDEOS',
  KHAZANA_LIBRARY_LECTURE='KHAZANA_LIBRARY_LECTURE',
  KHAZANA_RECENT_VIDEOS = 'KHAZANA_RECENT_VIDEOS',
}
