<mat-toolbar
  *ngIf="
    !(hideHeader$ | async) &&
    !router.url.includes('/cohort-selection') &&
    !router.url.includes('/vp-live') &&
    !router.url.includes('/rv-subject-topics') &&
    !router.url.includes('/pw-scholars') &&
    !router.url.includes('payments') &&
    !router.url.includes('job-alert') &&
    !router.url.includes('new-after-payment') &&
    !router.url.includes('new-overview') &&
    !router.url.includes('my-dashboard') &&
    !(this.router.url.endsWith('k8') && this.router.url.includes('open-pdf')) &&
    !router.url.includes('video-player-public') &&
    !router.url.includes('mahapack-overview') &&
    !router.url.includes('pw-centers') &&
    !router.url.includes('scholarship') &&
    !router.url.includes('mentorship') &&
    !router.url.includes('test-series') &&
    !router.url.includes('centres') &&
    !router.url.includes('micro-learning') &&
    !router.url.includes('library/recent-topics') &&
    !router.url.includes('batch-video-player') &&
    !router.url.includes('referral-new/tnc') &&
    !router.url.includes('/auth') &&
    router.url !== '/batch/batch-player' &&
    !router.url.includes('/batch-overview') &&
    !router.url.includes('/premium-batch-overview') &&
    !router.url.includes('/subject-topics') &&
    !router.url.includes('/subject-weekly') &&
    !router.url.includes('test-engine') &&
    !router.url.includes('exercise') &&
    !router.url.includes('test-result') &&
    !router.url.includes('not-found') &&
    !router.url.includes('q-bank-result') &&
    !router.url.includes('course-video-player') &&
    !router.url.includes('batch-overview/order-summary') &&
    !router.url.includes('video-solution') &&
    !router.url.includes('/test-list') &&
    !router.url.includes('recent-learning') &&
    !router.url.includes('/after-payment') &&
    !router.url.includes('/profile') &&
    !router.url.includes('/referral-new') &&
    !router.url.includes('/contact-us') &&
    !router.url.includes('/my-purchase') &&
    !router.url.includes('/feeds') &&
    !router.url.includes('/wallet') &&
    !router.url.includes('/open-pdf') &&
    !router.url.includes('my-batches') &&
    !router.url.includes('similar-batches') &&
    !router.url.includes('/logout') &&
    !(router.url.includes('search') && this.router.url.includes('library')) &&
    !(router.url.includes('chapters') && this.router.url.includes('library')) &&
    !(router.url.includes('offers') && this.router.url.includes('payment')) &&
    !(
      router.url.includes('test-series') && this.router.url.includes('explore')
    ) &&
    !(
      router.url.includes('test-series') &&
      this.router.url.includes('subjective')
    ) &&
    !(router.url.includes('offers') && this.router.url.includes('details')) &&
    !(
      router.url.includes('program') &&
      this.router.url.includes('chapters') &&
      this.router.url.includes('course')
    ) &&
    !(
      router.url.includes('program') &&
      this.router.url.includes('subject') &&
      this.router.url.includes('chapters')
    ) &&
    !(
      router.url.includes('books-public') &&
      router.url.includes('order-summary')
    ) &&
    !(
      router.url.includes('books-public') &&
      router.url.includes('address-overview')
    ) &&
    !(
      router.url.includes('books-public') &&
      router.url.includes('overview-data')
    ) &&
    !router.url.endsWith('/chat') &&
    !router.url.includes('referral-new/redeem-now') &&
    !router.url.includes('video-player-public') &&
    !router.url.includes('new-infinite-practice-engine') &&
    !router.url.includes('new-infinite-practice-result') &&
    !router.url.includes('infinite-practice-sessions') &&
    !router.url.includes('infinite-practice-leaderboard') &&
    !router.url.includes('infinite-practice') &&
    !router.url.includes('under-maintenance') &&
    !router.url.includes('free-content') &&
    !router.url.includes('battle-ground') &&
    !router.url.includes('ai-guru') &&
    !router.url.includes('sahayak') &&
    !router.url.includes('ai-evaluation')
  "
  [ngClass]="{
    'app-nav-toolbar': router.url === '/',
    'back-btn-in-page': router.url !== '/',
    'sticky top-0 z-50': router.url.includes('batches')
  }"
  class="header-height"
>
  <div
    *ngIf="
      !isAuth &&
      (router.url === '/library' ||
        router.url === '/contact-us-public' ||
        router.url === '/privacy-policy' ||
        router.url === '/refund-policy' ||
        router.url === '/under-maintenance')
    "
  >
    <div (click)="onClick()" class="p-4 md:p-6 vertical-align">
      <img [src]="pwLogo" class="logo-img mr-3" />
      <h1 class="bold body-1 cursor-pointer">Physics Wallah</h1>
    </div>
  </div>
  <div
    *ngIf="
      router.url !== '/dashboard' &&
      router.url !== '/doubts-connect/summary' &&
      !router.url.includes('test-series') &&
      !router.url.includes('test-list') &&
      router.url !== '/library' &&
      !router.url.includes('after-payment') &&
      router.url !== '/contact-us-public' &&
      router.url !== '/privacy-policy' &&
      router.url !== '/refund-policy' &&
      router.url !== '/under-maintenance' &&
      !router.url.endsWith('/chat') &&
      router.url !== '/school-contact-program'
    "
    class="sm:block"
  >
    <button
      (click)="onClick()"
      *ngIf="
        (!mobileQuery.matches &&
          !showMenuBtn() &&
          !router.url.includes('saarthi') &&
          !router.url.includes('batches?')) ||
        (!mobileQuery.matches && router.url === '/student-master-program')
      "
      backButton
      class="bg-white hover:bg-gray-100 text-gray-800 font-semibold rounded-full"
      mat-button
    >
      <mat-icon>arrow_back_ios</mat-icon>
      Back
    </button>

    <div *ngIf="router.url.includes('saarthi')" class="container">
      <div class="select" (click)="examDetails()">
        <div class="exam-title">
          {{ selectedExam }} -
          {{ selectedClass }}
        </div>
        <div>
          <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
      </div>
    </div>

    <!-- <div *ngIf="router.url === '/batches'" class="container" #batchDialog>
      <div class="batch-select" (click)="batchExamDetail(batchDialog)">
        <div class="batch-dialog-title">{{ userSelectedExam }}</div>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
    </div> -->
  </div>
  <div
    *ngIf="
      router.url.includes('test-list') || router.url.includes('test-series')
    "
    class="hidden sm:block"
  >
    <button
      (click)="goTobatch(router.url)"
      *ngIf="!mobileQuery.matches && !showMenuBtn()"
      class="bg-white hover:bg-gray-100 text-gray-800 font-semibold rounded-full"
      mat-button
    >
      <mat-icon>arrow_back_ios</mat-icon>
      Back
    </button>
  </div>
  <div
    *ngIf="isAuth"
    class="flex w-full"
    [ngClass]="
      router.url.includes('batches') || router.url.includes('vp-live')
        ? 'main-container'
        : ''
    "
  >
    <div class="flex items-center justify-between grow">
      <button
        (click)="toggleSideNav()"
        *ngIf="
          mobileQuery.matches &&
          showMenuBtn() &&
          (!router.url.includes('results') ||
            (shouldShowProfile && router.url.includes('results')))
        "
        mat-button
        id="menu_btn"
        [ngClass]="router.url.includes('saarthi') ? 'saarthi-button' : ''"
      >
        <mat-icon class="menu-icon">menu</mat-icon>
      </button>

      <button
        (click)="onClick()"
        *ngIf="
          (mobileQuery.matches &&
            !showMenuBtn() &&
            router.url !== '/saarthi' &&
            !router.url.includes('batches?')) ||
          (!shouldShowProfile && router.url.includes('results'))
        "
        backButton
        class="bg-white hover:bg-gray-100 text-gray-800 font-semibold rounded-full"
        mat-button
      >
        <mat-icon>arrow_back_ios</mat-icon>
        Back
      </button>
      <!-- <div class="container">
        <div class="select" (click)="examDetails()">
          <div class="exam-title">
            {{ selectedExam === '' ? userExam : selectedExam }} -
            {{ selectedClass === '' ? userClass : selectedClass }}
          </div>
          <div>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </div>
        </div>
      </div> -->

      <!--<button (click)="notifClick()" *ngIf="router.url !== '/batch' && mobileQuery.matches && showMenuBtn()" mat-button>
        <mat-icon class="notif-btn">notifications</mat-icon>
      </button>-->

      <!----------------------- COHORT  ----------------------------->
      <button
        class="cohort-btn flex items-center gap-3 ml-2 lg:ml-6 lg:gap-4 px-2 pl-3 py-1"
        (click)="openCohortModal()"
        *ngIf="
          (router.url.endsWith('/batches') ||
            router.url.endsWith('/study') ||
            router.url.includes('/library') ||
            router.url.includes('/test-series') ||
            router.url.endsWith('/vp-live') ||
            router.url.includes('batches?')) &&
          currentCohort.length > 0
        "
        [id]="'cohort-btn-' + currentCohortId"
      >
        <div class="flex gap-2 justify-center items-center">
          <img
            *ngIf="cohortIcon.length"
            [src]="cohortIcon"
            alt="cohort"
            class="h-5"
          />
          <span class="body-2 semibold">{{ currentCohort }}</span>
        </div>

        <mat-icon class="primary-color">keyboard_arrow_right</mat-icon>
      </button>

      <div
        *ngIf="
          (router.url.endsWith('/batches') ||
            router.url.includes('batches?')) &&
          currentCohort.length === 0
        "
        class="cohort-btn flex items-center gap-3 ml-0 md:ml-5 lg:gap-4 px-2 pl-4 py-1"
      >
        <span class="purpule body-2 semibold">Loading...</span>
      </div>
    </div>

    <!-- ! Profile -->

    <div class="flex-1 flex items-center justify-end pr-2 md:pr-4">
      <!-- <button
        class="notification-style"
        (click)="notifClick()"
        *ngIf="
          router.url !== '/batches' && mobileQuery.matches && showMenuBtn()
        "
        mat-button
      >
        <mat-icon class="notif-btn">notifications</mat-icon>
      </button> -->

      <!-- <div (click)="redirectToRefer()" *ngIf="showLottie()" class="flex items-center cursor-pointer">
        <span class="caption-1 medium refer-text hidden md:block">Refer & Earn:</span>
        <div class="lottie-div relative flex flex-col items-center justify-center">
          <ng-lottie (animationCreated)="animationCreated($event)" [height]="mobileQuery.matches ? '65px' : '80px'"
            [options]="options" [width]="mobileQuery.matches ? '65px' : '80px'"></ng-lottie>
          <span class="absolute bottom-0 caption-2 bold px-4 py-0">{{
            userInfo['profileId']['wallet']
            }}</span>
        </div>
      </div> -->

      <!-- <div
        *ngIf="!mobileQuery.matches"
        class="flex items-center mx-2 pr-4 cursor-pointer"
      >
        <span class="caption-1 medium download-text">Download App</span>
        <img
          (click)="openPlaystore('android')"
          [src]="playstoreImg"
          class="object-contain w-20 mx-2"
        />

        <img
          (click)="openPlaystore('apple')"
          [src]="appstoreImg"
          class="object-contain w-20 pr-1"
        />
      </div> -->

      <!-- <div
        class="flex md:flex-row flex-col gap-0 md:gap-2 border-right md:pr-4 pr-2 cursor-pointer md:mx-4 items-center"
        (click)="goToReferEarn()"
        *ngIf="isReferEarnEnable && !router.url.endsWith('/batches/study')"
      >
        <img
          *ngIf="referEarnData?.referAndEarn?.icon?.length"
          [src]="referEarnData?.referAndEarn?.icon"
          alt="refer-earn"
          class="max-h-10"
        />
        <span class="body-2 semibold">{{
          referEarnData?.referAndEarn?.text
        }}</span>
      </div> -->
      <div *ngIf="isScholarEnabled && router.url.endsWith('/batches/study')">
        <div
          class="flex flex-row rounded-3xl mx-2 justify-center items-center scholar cursor-pointer"
          (click)="goToScholar()"
        >
          <img [src]="scholarLogo" alt="" class="object-contain w-10 h-10" />
        </div>
      </div>
      <xp-header-button
        [isFromVideo]="true"
        [isFromCommonHeader]="true"
      ></xp-header-button>

      <div
        class="flex items-center"
        *ngIf="shouldShowProfile"
        [matMenuTriggerFor]="menu"
      >
        <h4 *ngIf="!mobileQuery.matches" class="hi-text body-2">
          Hi, {{ userInfo?.firstName || '' }}
        </h4>
        <div
          class="flex items-center justify-center p-1 profile-img-div md:ml-4"
        >
          <img
            [src]="
              userInfo && userInfo.imageId
                ? userInfo.imageId.baseUrl + userInfo.imageId.key
                : userInfo &&
                  userInfo['profileId'].gender &&
                  userInfo['profileId'].gender === 'Male'
                ? boyImg
                : girlImg
            "
            alt=""
            class="rounded-full"
          />
        </div>
        &nbsp;
        <span
          class="flex items-center justify-center caret down cursor-pointer arrow"
        ></span>
      </div>
      <mat-menu #menu="matMenu" class="mat-menu">
        <div
          (click)="goToProfile()"
          class="flex items-center"
          mat-menu-item
          *ngIf="isProfileEnabled"
        >
          <mat-icon>perm_identity</mat-icon>
          <span class="body-2 medium">My Profile</span>
        </div>
        <div
          (click)="goToPurchase()"
          class="flex items-center border-bottom"
          mat-menu-item
          *ngIf="isMyPurchaseEnabled"
        >
          <mat-icon>card_travel</mat-icon>
          <span class="body-2 medium">My Purchase</span>
        </div>
        <span class="body-2 medium" (click)="handleLogout()" mat-menu-item>
          <mat-icon>logout</mat-icon>Logout
        </span>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
